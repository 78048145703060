import { Fragment, useEffect, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import { signInWithMicrosoft } from "../auth";
import AwaitingVerification from "./AwaitingVerification";

interface SignInWorkaroundProps {
  open: boolean;
  onClose: () => void;
}

export default function SignInWorkaround({ open, onClose }: SignInWorkaroundProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [department, setDepartment] = useState<string | null>(null);
  const [isOslo, setIsOslo] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [step, setStep] = useState("emailInput");
  const [showPasswordField, setShowPasswordField] = useState(false); // State to manage showing password field

  useEffect(() => {
    const uid = localStorage.getItem("uid");
    if (uid) {
      const emailPatternOslo =
        /^([a-zA-Z]+)\.([a-zA-Z]+)@([a-zA-Z]+)\.oslo\.kommune\.no$/;
      const matchOslo = uid.match(emailPatternOslo);
      if (matchOslo) {
        setIsOslo(true);
        setFirstName(matchOslo[1]);
        setLastName(matchOslo[2]);
        setDepartment(matchOslo[3]);
      } else {
        setIsOslo(false);
        setFirstName(null);
        setLastName(null);
        setDepartment(null);
      }
      if (uid.endsWith("@ahlsell.no")) {
        window.location.href = "/ahlsell";
      }
      if (uid.endsWith("@fikse.co")) {
        // window.location.href = '/admin';
      }
    }
  }, [email]);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleConfirmClick = () => {
    if (email) {
      if (showPasswordField) {
        // Sign in with email and password
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            const user = userCredential.user;
            localStorage.setItem("uid", email);
            localStorage.setItem("verifiedUid", email);
            window.location.href = "/";
          })
          .catch((error) => {
            alert("Feil ved innlogging: " + error.message);
          });
      } else {
        // Email verification
        localStorage.setItem("uid", email);
        setStep("awaitingVerification");
      }
    } else {
      alert("Vennligst skriv inn en gyldig e-postadresse.");
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className="fixed inset-0 z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto sm:pb-0 sm:pb-20">
          <div className="flex h-full w-full items-center justify-center sm:h-auto sm:w-auto">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative h-full w-screen transform overflow-auto bg-white text-left shadow-xl transition-all sm:h-auto sm:w-full sm:max-w-lg`}
              >
                <div className="absolute right-0 top-0 pr-4 pt-4">
                  <button
                    type="button"
                    className="bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-[#8cd7ff] focus:ring-offset-2"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                {step === "emailInput" ? (
                  <div>
                    <h1 className="ml-6 pt-4 text-3xl tracking-tight">
                      Logg inn hos Fikse
                    </h1>
                    <div className="mt-4 border">
                      <p className="mt-4 p-4 text-lg leading-8 text-gray-700">
                        Vi vil plassere deg på rett sted basert på din e-post, og sende
                        oppdateringer på mail.
                      </p>
                      <div className="mt-6 flex max-w-lg gap-x-4">
                        <label htmlFor="email-address" className="sr-only">
                          Email address
                        </label>
                        <input
                          id="email-address"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          className="min-w-0 flex-auto border-b border-t border-black bg-white/5 p-4 px-3.5 sm:text-sm sm:leading-6"
                          placeholder="fornavn.etternavn@etat.oslo.kommune.no"
                          value={email}
                          onChange={handleEmailChange}
                        />
                      </div>
                      {showPasswordField && (
                        <div className="flex max-w-lg gap-x-4">
                          <label htmlFor="password" className="sr-only">
                            Password
                          </label>
                          <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            className="min-w-0 flex-auto border-0 border-b border-black bg-white/5 p-4 sm:text-sm sm:leading-6"
                            placeholder="Passord"
                            value={password}
                            onChange={handlePasswordChange}
                          />
                        </div>
                      )}
                      <div className="w-full">
                        <button
                          type="button"
                          className="w-full border-b border-black bg-[#8cd7ff] p-4 text-left text-sm hover:bg-[#7bc3e0]"
                          onClick={handleConfirmClick}
                        >
                          Bekreft og logg inn
                        </button>
                      </div>
                      <p
                        className="cursor-pointer border-b border-black bg-gray-100 p-4 text-sm text-gray-600"
                        onClick={() => setShowPasswordField(!showPasswordField)}
                      >
                        {showPasswordField
                          ? "Tilbake til e-post verifikasjon"
                          : "Logg inn med passord"}
                      </p>
                    </div>
                    <div className="w-full border-black p-5 text-center text-sm"></div>
                    <div className="w-full border-b border-black p-5 text-center text-sm">
                      Eller logg inn med en av disse
                    </div>
                    <button
                      type="button"
                      className="w-full border-b border-black bg-[#8cd7ff] p-4 text-left text-sm hover:bg-[#7bc3e0]"
                      onClick={async () => {
                        await signInWithMicrosoft();
                        if (localStorage.getItem("uid")) window.location.reload();
                      }}
                    >
                      Microsoft
                    </button>
                  </div>
                ) : (
                  //localStorage.getItem("uid") ? <AwaitingVerification email={email} /> : null
                  <AwaitingVerification email={email} />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
