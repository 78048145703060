import React, { useEffect, useRef, useState } from "react";

import { set } from "date-fns";
import { collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import { useParams } from "react-router-dom";

import AwaitingVerification from "../components/AwaitingVerification";
import DeviationPromt from "../components/DeviationPromt";
import FormattedTimestamp from "../components/FormatTimeAndDate";
import ModalTile from "../components/ModalTile";
import { sendSMS } from "../components/contact";
import { newActivity } from "../components/firebaseCommands";
import AvvikIOrdre, { cancelOrderInFirestore } from "../components/newOrder/AvvikInOrder";
import ConfirmationView from "../components/newOrder/ConfirmationView";
import DropOffLocation from "../components/newOrder/DropOffLocation";
import { fallbackImageUrl } from "../components/newOrder/OrderModal";
import { db } from "../firebase";
import animationData from "../images/FikseAnimation.json";
import { ReactComponent as FikseLogo } from "../images/fikse-logo.svg";
import { ReactComponent as ChevronRightIcon } from "../images/icons-fikse/chevron.svg";
import { ReactComponent as DeliverIcon } from "../images/icons-fikse/deliver.svg";
import { ReactComponent as SignIcon } from "../images/icons-fikse/sign.svg";
import OrderCard, { finalEventsStateTailor } from "./OrderPage/OrderCard";
import OrderCardDetails from "./OrderPage/OrderCardDetails";
import OrderLog from "./OrderPage/OrderLog";
import OrderTabs from "./OrderPage/OrderTabs";

/* Oppdatert OrderInfo- og Order-typer */

interface OrderPageProps {
  database: string;
}
export interface OrderInfo {
  item: string;
  problem: string;
  fix_type: string;
  description: string;
  price_nok: number;
  customerComment?: string;
  imageUrl: string;
  work?: string; // Optional work field
  tailorNote?: string; // Optional tailor note field
  previousPrice?: number[]; // Optional previous price field
  count?: number; // Optional count field
  event?: Event[]; // Optional event field // Optional event field
}

export interface Event {
  date: string;
  status: string;
  description?: string;
  handler: string;
  Price?: number;
  tailorNote?: string;
  oldPrice?: number;
  newPrice?: number;
}

export interface Order {
  id: string;
  date: string;
  orderInfo: OrderInfo[];
  totalPrice: string;
  deliveryMethod: string;
  retrieveMethod: string;
  contactInfo: {
    department: string;
    name: string;
    phone: string;
    address?: string;
  };
  receiveMarketing: boolean;
  email: string;
  paid: boolean;
  qrCodeUrl?: string;
  activity: ActivityItem[];
  resourceNumber?: string;
  deliveryAddress?: string;
  deliveryDepartment?: string;
  retrieveAddress?: string;
  tailorsNote?: string;
  orderCreationUrl?: string;
}

export interface ActivityItem {
  id: number;
  content: string;
  message?: string;
  date: string;
  dateTime: string;
  iconBackground: string;
  deviationPrice?: number;
  deviationdeResponse?: string;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const LoadingSpinner: React.FC = () => (
  <div className="flex items-center justify-center py-10">
    <Lottie options={defaultOptions} />
  </div>
);

const ErrorBanner: React.FC<{ message: string }> = ({ message }) => {
  const { t } = useTranslation();
  return (
    <div
      className="relative mb-6 rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
      role="alert"
    >
      <strong className="font-bold">{t("error")}</strong>
      <span className="block sm:inline">{message}</span>
    </div>
  );
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const orderStates: string[] = [
  "orderStateCreated",
  "orderStateCustomerDropOff",
  "orderStateDeliverToTailor",
  "orderStateRepaired",
  "orderStatePickedUpFromTailor",
  "orderStateDelivered",
];

const WorkwearOrderPage: React.FC<OrderPageProps> = ({ database }) => {
  const { orderId } = useParams<{ orderId: string }>();
  const [order, setOrder] = useState<Order | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [showUpdateStatus, setShowUpdateStatus] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showDropOff, setShowDropOff] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [focusedOrderItems, setFocusedOrderItems] = useState<number>(
    window.location.hash ? parseInt(window.location.hash.slice(1)) - 1 : -1
  );
  const [AvvikModal, setAvvikModal] = useState<boolean>(false);

  const { t } = useTranslation();
  const hasEffectRun = useRef(false);
  const lastActivityContent = order?.activity[order?.activity.length - 1].content;
  const finishedOrder: boolean =
    lastActivityContent === orderStates[orderStates.length - 1];
  const signleItemOrder: boolean = order?.orderInfo.length === 1;

  const fetchOrder = async () => {
    try {
      setLoading(true);
      setError(null);

      if (!orderId) {
        throw new Error(t("workwearOrderPage.noOrderId"));
      }

      const orderRef = doc(db, database, orderId);
      const orderSnap = await getDoc(orderRef);

      if (!orderSnap.exists()) {
        throw new Error(t("workwearOrderPage.orderNotFound"));
      }

      const data = orderSnap.data() as Omit<Order, "id">;

      setOrder({ id: orderId, ...data });
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError(t("workwearOrderPage.unknownError"));
      }
    } finally {
      const randomDelay = Math.floor(Math.random() * (850 - 600 + 1)) + 600;
      const minLoadingTime = new Promise((resolve) => setTimeout(resolve, randomDelay));
      await Promise.all([minLoadingTime]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (hasEffectRun.current) return;
    hasEffectRun.current = true;

    fetchOrder();
  }, [orderId]);

  useEffect(() => {
    const verifiedUid = localStorage.getItem("verifiedUid");
    const uid = localStorage.getItem("uid");

    if (verifiedUid === uid) {
      setIsVerified(true);
    }
  }, []);

  useEffect(() => {
    if (window.location.hash === "#newOrder") {
      setShowConfirmation(true);
    }

    if (window.location.hash === "#scanned" || window.location.hash === "#checkPayment") {
      setFocusedOrderItems(-1);
    }
  }, []);

  /*Conditions to check if bottom bar is visible*/
  useEffect(() => {
    const roles = localStorage.getItem("roles");
    const lastIndexOf = order?.activity ? order.activity.length - 1 : -1;

    setShowUpdateStatus(false);

    if (
      order?.activity &&
      (order.activity[lastIndexOf].message === "Bestilling opprettet" ||
        order.activity[lastIndexOf].content === "orderStateCreated")
    ) {
      setShowUpdateStatus(true);
    }

    //checks if there are any event that are not approved by the customer
    /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */ /*UNDER WORK, START HER, forsett jher.           finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
 er ikke fikset  */
    const allItemsApproved = order?.orderInfo.every(
      (item) =>
        !item.event ||
        (item.event.length > 0 &&
          finalEventsStateTailor.includes(item.event[item.event.length - 1].status))
    );
    if (
      order?.activity &&
      order.activity[lastIndexOf].content === "orderStateDeliverToTailor" &&
      roles?.includes("tailor") /*&&      allItemsApproved*/
    ) {
      setShowUpdateStatus(true);
    }

    if (
      order?.activity &&
      (order.activity[lastIndexOf].content === "orderStateCustomerDropOff" ||
        order.activity[lastIndexOf].content === "orderStateRepaired") &&
      roles?.includes("transporter")
    ) {
      setShowUpdateStatus(true);
    }

    if (
      order?.activity &&
      order.activity[lastIndexOf].content === "orderStatePickedUpFromTailor" &&
      roles?.includes("courier")
    ) {
      setShowUpdateStatus(true);
    }
  }, [order, orderStates]);

  const handleStatusUpdate = async (newStatus: string) => {
    if (newStatus) {
      const orderRef = doc(db, database, orderId!);
      const updatedActivity = [
        ...order!.activity,
        {
          id: order!.activity.length + 1,
          content: newStatus,
          date: new Date().toLocaleDateString(),
          dateTime: new Date().toISOString(),
          iconBackground: "bg-blue-500",
        },
      ];

      await updateDoc(orderRef, {
        currentStatus: newStatus,
        activity: updatedActivity,
      });

      let smsMessage = "";
      if (newStatus === "Done at tailor") {
        smsMessage =
          "Din ordre er ferdig hos skredderen. Vi vil varsle deg når plagget er tilbake på utleveringsstedet.";
      } else if (newStatus === "Delivered back to dropoff") {
        smsMessage = `Plagget ditt er levert tilbake til utleveringsstedet. Du kan hente det på ${
          order!.deliveryAddress
            ? order!.deliveryAddress
            : "det angitte utleveringsstedet"
        }. Husk å hente plagget så snart som mulig.`;
      }
      if (smsMessage && order!.contactInfo.phone) {
        try {
          const messageData = {
            to: "+47" + order!.contactInfo.phone,
            from: "Fikse",
            body: smsMessage,
            sentAt: new Date().toISOString(),
            orderId: orderId,
          };
          await setDoc(doc(collection(db, "messages")), messageData);
          console.log("SMS message added to collection");
        } catch (error) {
          console.error("Error adding SMS message to collection: ", error);
        }
      }
      fetchOrder();
      setIsModalOpen(false);
    } else {
      alert("Vennligst velg en status for å oppdatere.");
    }
  };

  const handleProceed = () => {
    setShowConfirmation(false);
    window.history.replaceState(null, "", " ");
  };

  const email = localStorage.getItem("uid") || "";

  //denne her ser ut til å være en funksjon som sender en epost til kunden om at ordren er mottatt. Men ikke fullført?
  const handleSendOrderConfirmation = async () => {
    if (order) {
      try {
        const functions = getFunctions(undefined, "europe-west1");
        const sendOrderConfirmation = httpsCallable(
          functions,
          "sendOrderConfirmationEmail"
        );

        const response = await sendOrderConfirmation({
          email: order.email,
          orderInfo: order.orderInfo,
          contactInfo: order.contactInfo,
          orderId: order.id,
        });

        const data = response.data as { success: boolean }; // Type-casting response.data

        if (data.success) {
          alert("Ordrebekreftelse sendt!");
        } else {
          alert("Noe gikk galt under sending av e-post.");
        }
      } catch (error) {
        console.error("Error sending order confirmation:", error);
        alert("Noe gikk galt under sending av e-post.");
      }
    }
  };

  const selectedFocusIndex = (index: number) => {
    setFocusedOrderItems(index);
  };

  return (
    <div className="mx-auto min-h-screen">
      {" "}
      {/* max-w-7xl*/}
      {loading && <LoadingSpinner />}
      {error && <ErrorBanner message={error} />}
      {!loading && !error && (
        <>
          {!isVerified ? (
            <AwaitingVerification email={email} />
          ) : showConfirmation ? (
            <ConfirmationView />
          ) : (
            <>
              {/*Header */}
              <h1 className="mb-8 pl-4 text-3xl">
                {t("workwearOrderPage.order")}: {order?.id.slice(0, 5)}
              </h1>
              {/*Avvik  SLETT */}
              {/*order?.activity &&
                order.activity[order.activity.length - 1].content ===
                  "orderStateDeliverToTailor" &&
                localStorage.getItem("roles")?.includes("tailor") && (
                  <>
                    <button
                      className="text-lef mb-14 w-full border-b border-t border-black bg-fikseRed p-4 text-base"
                      onClick={() => setAvvikModal(true)}
                    >
                      {
                        "dette er test tekst, denne må byttes ut, denne aktividere modal for avvik"
                      }
                    </button>

                    <ModalTile
                      open={AvvikModal}
                      onClose={() => setAvvikModal(false)}
                      title={t("CancelOrderTempTitle")}
                    >
                      <div className="space-y-6">
                        <p className="text-base">{t("cancelOrderConfirmation")}</p>
                        <div className="flex w-full border-t border-black">
                          <button
                            type="button"
                            className="flex-1 border-r border-black bg-fikseBeige p-4 text-base hover:bg-fikseBeigeHover"
                            onClick={() => setAvvikModal(false)}
                          >
                            {t("goBack")}
                          </button>
                          <button
                            type="button"
                            className="hover:bg-fikseRedHover flex-1 bg-fikseRed p-4 text-base"
                            onClick={() => {}}
                          >
                            {t("confirm")}
                          </button>
                        </div>{" "}
                      </div>
                    </ModalTile>
                  </>
                )*/}
              {/*tabs */}
              {!window.location.hash && (
                <OrderTabs
                  order={order!}
                  onChange={selectedFocusIndex}
                  activeTab={focusedOrderItems}
                />
              )}
              {order?.activity[order.activity.length - 1].message && (
                <>
                  <div className="flex w-full items-center border-t border-black bg-fikseBlue p-4 text-left text-base">
                    <SignIcon className="mr-2 h-6 w-6" />
                    <span>{t("markPackage") + " " + orderId?.slice(0, 5)}</span>
                  </div>
                  <div className="mb-10 flex w-full items-center border-b border-t border-black bg-fikseBlue p-4 text-left text-base">
                    <DeliverIcon className="mr-2 h-6 w-6" />
                    <span>
                      {t("nextStep") +
                        order?.deliveryDepartment +
                        t("nextStepTwo") +
                        order?.deliveryAddress +
                        t("nextStepThree")}
                    </span>
                  </div>
                </>
              )}
              {lastActivityContent === "orderDeviation" && (
                <DeviationPromt
                  newPrice={order?.activity[order.activity.length - 1].deviationPrice}
                  tailorsNote={order?.tailorsNote}
                  onDeclinedClick={async () => {
                    setLoading(true);
                    await cancelOrderInFirestore(database, orderId!, t);
                    window.location.reload();
                  }}
                  onAcceptClick={async () => {
                    setLoading(true);
                    await newActivity(database, orderId!, "orderStateDeliverToTailor");
                    window.location.reload();
                  }}
                />
              )}
              {order &&
                (localStorage.getItem("verifiedUid") === localStorage.getItem("uid") ? (
                  <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
                    <OrderCard
                      order={order}
                      activity={order.activity}
                      index={focusedOrderItems}
                    />
                    <OrderLog order={order} activity={order.activity} />
                    <div className="p-8" />
                  </div>
                ) : (
                  <AwaitingVerification email={email} />
                ))}{" "}
              {/*order?.activity &&
                order.activity[order.activity.length - 1].content ===
                  "orderStateDeliverToTailor" &&
                localStorage.getItem("roles")?.includes("tailor") && (
                  <AvvikIOrdre
                    orderType=database
                    orderId={orderId!}
                    onButtonClick={() => setLoading(true)}
                  />
                )*/}
              {/*BottomBar*/}
              {showUpdateStatus && (
                <>
                  <button
                    onClick={async () => {
                      setLoading(true);
                      let newStatus;
                      switch (lastActivityContent) {
                        case orderStates[0]:
                          newStatus = orderStates[1];
                          break;
                        case orderStates[1]:
                          newStatus = orderStates[2];
                          break;
                        case orderStates[2]:
                          newStatus = orderStates[3];
                          break;
                        case orderStates[3]:
                          newStatus = orderStates[4];
                          break;
                        case orderStates[4]:
                          newStatus = orderStates[5];
                          break;
                        default:
                          newStatus = orderStates[1];
                          break;
                      }

                      if (
                        newStatus === orderStates[orderStates.length - 2] &&
                        order?.resourceNumber
                      ) {
                        //if løkke som aktiveres når en er på nest siste steg. siste er plukket opp av kunde. nest siste er klar for plukking opp.
                        const firstName = order?.contactInfo.name.split(" ")[0];
                        await sendSMS(
                          order?.contactInfo.phone!,
                          orderId!,
                          `Hei ${firstName} 🎉\n\n` +
                            `Din bestilling er ferdig fikset og klar for henting på ${order?.deliveryDepartment}\n\n` +
                            `Når du henter, husk å markere "Jeg har hentet pakken". Se bestillingen din her: https://oslo.kommune.fikse.co/orders/workwear/${orderId}\n\n` +
                            `Vi håper du blir fornøyd!\n\n` +
                            `Hilsen Team Fikse`
                        );
                      }
                      if (!finishedOrder) {
                        setSelectedStatus(newStatus); // Update the state if needed elsewhere
                        handleStatusUpdate(newStatus);
                      } // Pass}
                    }}
                    className="fixed bottom-0 left-0 flex w-full items-center justify-between border-b border-t border-black bg-fikseGreen p-4 px-8 py-4 text-xl"
                  >
                    <span className="md:mx-auto">
                      {order &&
                        order.activity &&
                        `${
                          order?.activity.length == 1
                            ? t("workwearOrderPage.orderStateDeliveredBar")
                            : t(`workwearOrderPage.${lastActivityContent}Bar`)
                        }`}
                    </span>

                    {!finishedOrder && <ChevronRightIcon aria-hidden="true" />}
                  </button>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default WorkwearOrderPage;
