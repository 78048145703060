// src/components/Page.tsx
import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import NotFound from "../components/NotFound";
import ContentBlockRenderer from "./ContentBlockRenderer";
import client from "./sanityClient";

//import { PageData } from "../schema/types";

interface PageProps {
  slug: string;
}

const Page: React.FC = ({}) => {
  const { slug } = useParams<{ slug: string }>();
  const [pageData, setPageData] = useState([]) as any;

  console.log("slug", slug);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = `*[_type == "page" && slug.current == "${slug}"][0]`;
        console.log("query", query);
        // @ts-ignore
        const data = await client.fetch(query);
        console.log("data", data);
        console.log("slug", slug);
        setPageData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [slug]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 100);

    return () => clearTimeout(timer);
  }, [pageData]);

  if (isLoading && !pageData) {
    return <div>{/*Bugger plass i tiden det tar før den loader */}</div>;
  }

  if (!isLoading && !pageData) {
    return <NotFound />;
  }

  const stickyitems = pageData?.content?.find(
    (item: { _type: string }) => item._type === "stickyButtonsBlock"
  )?.buttons?.length;

  console.log("stickyitems", stickyitems);
  return (
    <div className="mx-auto">
      {pageData &&
        pageData.content &&
        pageData.content.map((block: any, index: any) => (
          <ContentBlockRenderer
            block={block}
            index={index}
            key={index}
            {...(stickyitems ? { offset: stickyitems } : {})}
          />
        ))}
    </div>
  );
};

export default Page;
