import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ReactComponent as FikseFamily } from "../../images/Illustration/familie.svg";
import jjLogo from "../../images/Logoer/JJ logo.png";
import acneLogo from "../../images/Logoer/acne.png";
import cirkularLogo from "../../images/Logoer/cirkulær.jpg";
import dapperLogo from "../../images/Logoer/dapper logo.webp";
import ensembleLogo from "../../images/Logoer/ensemble.png";
import heimenLogo from "../../images/Logoer/heimen.png";
import maleneBirgerLogo from "../../images/Logoer/logoMaleneBirger.png";
import sveanLogo from "../../images/Logoer/svean.png";
import { ReactComponent as ChevronRightIcon } from "../../images/icons-fikse/chevron.svg";
import SignInWorkaround from "../SignInWorkaround";
import OrderModal from "../newOrder/OrderModal";
import WorkwearModal from "../newOrder/WorkwearModal";
import AuthComponent from "../userAuthentication/loggInSample";
import { OrderModalButtons } from "./OrderModalButtons";

const MobileView = () => {
  const isLoggedIn = !!localStorage.getItem("uid");
  const [showSignIn, setShowSignIn] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [showWorkwearModal, setShowWorkwearModal] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const logos = [
    acneLogo,
    cirkularLogo,
    dapperLogo,
    ensembleLogo,
    heimenLogo,
    jjLogo,
    maleneBirgerLogo,
    sveanLogo,
  ];

  useEffect(() => {
    if (window.location.hash === "#workwear") {
      setShowWorkwearModal(true);
    }
  }, []);

  const isWorkWearDomain: boolean = (() => {
    const workwearDomains = ["oslo.kommune.fikse.co", "osloskolen.fikse.co"];

    return workwearDomains.some((domain) => window.location.hostname.includes(domain));
  })();

  const isWorkWear: boolean = (() => {
    const email = localStorage.getItem("verifiedUid");
    //  if (window.location.hostname === "oslo.kommune.fikse.co") return true;
    return email
      ? email.endsWith("oslo.kommune.no") ||
          email.endsWith("osloskolen.no") ||
          email.endsWith("fikse.co") ||
          email.endsWith("ntnu.no")
      : false;
  })();

  const handleCategoryClick = (category: string) => {
    let serviceCategory = "";
    switch (category) {
      case "Clothes":
        serviceCategory = i18n.language === "no" ? "NO-Clothes-0924" : "EN-Clothes-0924";
        break;
      case "Shoes":
        serviceCategory = i18n.language === "no" ? "NO-Shoes-0924" : "EN-Shoes-0924";
        break;
      case "Accessories":
        serviceCategory =
          i18n.language === "no" ? "NO-Accessories-0924" : "EN-Accessories-0924";
        break;
      case "Bunad":
        serviceCategory = i18n.language === "no" ? "NO-Bunad-0924" : "EN-Bunad-0924";
        break;
      case "Workwear":
        serviceCategory =
          i18n.language === "no"
            ? "NO-Workwear/Ahlsell-0924"
            : "EN-Workwear/Ahlsell-0924";
        break;
      default:
        serviceCategory = "EN-Clothes-0924";
    }

    localStorage.setItem("serviceCategory", serviceCategory);
    if (category === "Workwear") {
      setShowWorkwearModal(true);
    } else {
      setShowOrderModal(true);
    }
  };

  const handleCloseModal = (closeModalFn: () => void) => {
    setTimeout(() => {
      closeModalFn();
    }, 300);
  };

  const handleActiveOrderClick = () => {
    const activeOrder = localStorage.getItem("activeOrder");
    if (activeOrder) {
      navigate(`/orders/${activeOrder}`);
    }
  };

  return (
    <div className={`bg-white`}>
      <div className="py-4">
        <h2 className="text-md mb-8 ml-8 mr-8 pl-4 pr-4 sm:ml-16 sm:pl-0 sm:text-xl">
          {t("mobileView.chooseCategory")}
        </h2>
        <FikseFamily className="pl-12 pr-12" />
        {/* <div className="mt-4 flex flex-col-reverse items-center sm:-mt-8 sm:flex-row sm:justify-between">
          <img
            src="https://www.fikse.co/images/logo.svg"
            alt="Fikse"
            className="mx-auto h-auto w-10/12 sm:mx-0 sm:ml-16 sm:mt-auto sm:w-1/4"
          />
          <img
            src="https://www.fikse.co/images/illustration/category-shoes.svg"
            alt="Man with dog"
            className="mx-auto h-auto w-10/12 px-4 sm:mx-0 sm:w-1/4"
          />
        </div>
        */}
        <div className="mt-4">
          {localStorage.getItem("activeOrder") && (
            <div
              className="cursor-pointer border-t border-black bg-[#8cd7ff] p-4 px-8 text-xl sm:pl-16"
              onClick={handleActiveOrderClick}
            >
              {t("mobileView.activeOrder")}
            </div>
          )}
          {!isWorkWear && !isWorkWearDomain && (
            <OrderModalButtons
              categories={["Clothes", "Shoes", "Accessories", "Bunad", "Workwear"]}
            />
          )}
        </div>
      </div>

      <div className="px-8 py-4">
        <p className="text-md sm:ml-8 sm:text-xl">{t("mobileView.description1")}</p>
        <p className="text-md mt-4 sm:ml-8 sm:text-xl">{t("mobileView.description2")}</p>
        <div className="relative ml-8">
          <img
            src="https://www.fikse.co/images/cover-photo.png"
            alt="Seamstress"
            className="mt-8 h-auto w-full border-b border-t border-black sm:h-96 sm:object-cover"
          />
        </div>
      </div>

      <div className="px-4 py-4">
        <p className="text-md sm:pl-12 sm:text-xl">{t("mobileView.mission")}</p>
        <a
          href="#"
          className="-mx-4 mt-8 flex items-center justify-between border-b border-t border-black p-4 px-4 text-lg sm:pl-16 sm:text-3xl sm:hover:bg-gray-50"
        >
          <span>{t("mobileView.readMore")}</span>
          <ChevronRightIcon className="mt-1 h-5 w-5 text-gray-500" aria-hidden="true" />
        </a>
        <a
          href="#"
          className="-mx-4 flex items-center justify-between border-b border-black p-4 px-4 text-lg sm:pl-16 sm:text-3xl sm:hover:bg-gray-50"
        >
          <span>{t("mobileView.businessCustomer")}</span>
          <ChevronRightIcon className="mt-1 h-5 w-5 text-gray-500" aria-hidden="true" />
        </a>
      </div>

      <div className="overflow-hidden py-4">
        <div className="logo-scroll animate-scroll flex space-x-8">
          {logos.concat(logos).map((logo, index) => (
            <img
              key={index}
              src={logo}
              alt={`Logo ${index + 1}`}
              className="h-16 object-contain pl-20"
            />
          ))}
        </div>
      </div>

      <div className="py-4 text-center">
        <p className="text-md mx-auto mt-4 sm:text-xl">hei@fikse.co</p>
        <p className="mx-auto text-sm">484 50 390</p>
        <img
          src="https://www.fikse.co/images/illustration/footer-woman-cat.svg"
          alt="Fikse person"
          className="mx-auto mt-4 h-auto w-32"
        />
      </div>

      <div className="border-t border-black">
        <a
          href="#"
          className="flex items-center justify-between border-b border-black px-4 py-4 text-lg sm:pl-16 sm:text-3xl sm:hover:bg-gray-50"
        >
          <span>{t("mobileView.deliveryOptions")}</span>
          <ChevronRightIcon className="mt-1 h-5 w-5 text-gray-500" aria-hidden="true" />
        </a>
        <a
          href="#"
          className="flex items-center justify-between border-b border-black px-4 py-4 text-lg sm:pl-16 sm:text-3xl sm:hover:bg-gray-50"
        >
          <span>{t("mobileView.officeAgreements")}</span>
          <ChevronRightIcon className="mt-1 h-5 w-5 text-gray-500" aria-hidden="true" />
        </a>
      </div>

      <SignInWorkaround
        open={showSignIn}
        onClose={() => handleCloseModal(() => setShowSignIn(false))}
      />
      <OrderModal
        open={showOrderModal}
        onClose={() => handleCloseModal(() => setShowOrderModal(false))}
      />
      <WorkwearModal
        open={showWorkwearModal}
        onClose={() =>
          handleCloseModal(() => {
            setShowWorkwearModal(false);

            isWorkWear && setShowOrderModal(true);
          })
        }
      />
    </div>
  );
};

export default MobileView;
