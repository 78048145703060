import React, { useState } from "react";

import { set } from "date-fns";
import { title } from "process";
import { useTranslation } from "react-i18next";

import i18n from "../../i18n";
import { ReactComponent as ChevronRightIcon } from "../../images/icons-fikse/chevron.svg";
import ModalTile from "../ModalTile";
import SignInWorkaround from "../SignInWorkaround";
import OrderModal from "../newOrder/OrderModal";
import WorkwearModal from "../newOrder/WorkwearModal";

export const OrderModalButtons = ({
  categories,
}: {
  categories: string[];
  office?: number;
}) => {
  const { t } = useTranslation();
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [showWorkwearModal, setShowWorkwearModal] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [logginModal, setLogginModal] = useState(false);
  const [officeEmail, setOfficeEmail] = useState("");
  const [serviceCategoryBuffer, setServiceCategoryBuffer] = useState("");
  const [notValidOfficeEmail, setNotValidOfficeEmail] = useState(false);

  const workwearEmails = ["nrk.no"];
  const workwearDomains = [
    "avtale.fikse.co/offices",
    "dev.avtale.fikse.co:3000/offices",
    "fiksefirstapp.web.app/offices",
    "localhost:3000/offices",
  ];

  const handleCloseModal = (closeModalFn: () => void) => {
    setTimeout(() => {
      closeModalFn();
    }, 300);
  };
  const isWorkWearDomain: boolean = (() => {
    const workwearDomains = ["oslo.kommune.fikse.co", "osloskolen.fikse.co"];

    return workwearDomains.some((domain) => window.location.hostname.includes(domain));
  })();

  const checkOfficeEmail = () => {
    return workwearEmails.includes(officeEmail.split("@")[1] ?? "");
  };

  const validateOfficeEmailAndProcceed = () => {
    if (checkOfficeEmail()) {
      handleCategoryClick(serviceCategoryBuffer);
      setNotValidOfficeEmail(false);
    } else {
      setServiceCategoryBuffer("");
    }
  };

  const handleCategoryClick = (category: string) => {
    const currentDomain = window.location.href.replace(/^https?:\/\//, "").split("#")[0];
    localStorage.setItem("officeEmail", "");
    if (
      workwearDomains.includes(currentDomain) &&
      !workwearEmails.includes(officeEmail.split("@")[1] ?? "")
    ) {
      setServiceCategoryBuffer(category);
      setLogginModal(true);
      return;
    }

    let serviceCategory = "";
    switch (category) {
      case "Clothes":
        serviceCategory = i18n.language === "no" ? "NO-Clothes-0924" : "EN-Clothes-0924";
        break;
      case "Shoes":
        serviceCategory = i18n.language === "no" ? "NO-Shoes-0924" : "EN-Shoes-0924";
        break;
      case "Accessories":
        serviceCategory =
          i18n.language === "no" ? "NO-Accessories-0924" : "EN-Accessories-0924";
        break;
      case "Bunad":
        serviceCategory = i18n.language === "no" ? "NO-Bunad-0924" : "EN-Bunad-0924";
        break;
      case "Workwear":
        serviceCategory =
          i18n.language === "no"
            ? "NO-Workwear/Ahlsell-0924"
            : "EN-Workwear/Ahlsell-0924";
        break;
      default:
        serviceCategory = "EN-Clothes-0924";
    }

    localStorage.setItem("serviceCategory", serviceCategory);
    if (category === "Workwear") {
      setShowWorkwearModal(true);
    } else {
      setShowOrderModal(true);
    }
  };

  const isWorkWear: boolean = (() => {
    const email = localStorage.getItem("verifiedUid");
    //  if (window.location.hostname === "oslo.kommune.fikse.co") return true;
    return email
      ? email.endsWith("oslo.kommune.no") ||
          email.endsWith("osloskolen.no") ||
          email.endsWith("fikse.co") ||
          email.endsWith("ntnu.no")
      : false;
  })();

  const handleEmailSubmit = () => {
    if (officeEmail) {
      localStorage.setItem("officeEmail", officeEmail);
      setLogginModal(false);
    }
  };

  return (
    <>
      {!isWorkWear && !isWorkWearDomain && (
        <>
          {categories.includes("Clothes") && (
            <a
              href="#"
              className="flex items-center justify-between border-b border-t border-black p-4 px-8 text-lg sm:pl-16 sm:text-3xl sm:hover:bg-gray-50"
              onClick={() => handleCategoryClick("Clothes")}
            >
              <span>{t("mobileView.clothes")}</span>
              <ChevronRightIcon
                className="mt-1 h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
            </a>
          )}
          {categories.includes("Shoes") && (
            <a
              href="#"
              className="flex items-center justify-between border-b border-black p-4 px-8 text-lg sm:pl-16 sm:text-3xl sm:hover:bg-gray-50"
              onClick={() => handleCategoryClick("Shoes")}
            >
              <span>{t("mobileView.shoes")}</span>
              <ChevronRightIcon
                className="mt-1 h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
            </a>
          )}
          {categories.includes("Accessories") && (
            <a
              href="#"
              className="flex items-center justify-between border-b border-black p-4 px-8 text-lg sm:pl-16 sm:text-3xl sm:hover:bg-gray-50"
              onClick={() => handleCategoryClick("Accessories")}
            >
              <span>{t("mobileView.accessories")}</span>
              <ChevronRightIcon
                className="mt-1 h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
            </a>
          )}
          {categories.includes("Bunad") && (
            <a
              href="#"
              className="flex items-center justify-between border-b border-black p-4 px-8 text-lg sm:pl-16 sm:text-3xl sm:hover:bg-gray-50"
              onClick={() => handleCategoryClick("Bunad")}
            >
              <span>{t("mobileView.bunad")}</span>
              <ChevronRightIcon
                className="mt-1 h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
            </a>
          )}
        </>
      )}

      {categories.includes("Workwear") && (
        <a
          href="#"
          className="flex items-center justify-between border-b border-black p-4 px-8 text-lg sm:pl-16 sm:text-3xl sm:hover:bg-gray-50"
          onClick={() => handleCategoryClick("Workwear")}
        >
          <span>{t("mobileView.workwear")}</span>
          <ChevronRightIcon className="mt-1 h-5 w-5 text-gray-500" aria-hidden="true" />
        </a>
      )}
      <SignInWorkaround
        open={showSignIn}
        onClose={() => handleCloseModal(() => setShowSignIn(false))}
      />
      <OrderModal
        open={showOrderModal}
        onClose={() => handleCloseModal(() => setShowOrderModal(false))}
      />
      <WorkwearModal
        open={showWorkwearModal}
        onClose={() =>
          handleCloseModal(() => {
            setShowWorkwearModal(false);

            isWorkWear && setShowOrderModal(true);
          })
        }
      />
      <ModalTile
        open={logginModal}
        onClose={() => {
          setLogginModal(false);
          validateOfficeEmailAndProcceed();

          setTimeout(() => {
            setNotValidOfficeEmail(false);
          }, 300);
        }}
        title="Din epost"
      >
        <div>
          {notValidOfficeEmail && (
            <div className="px-6 pb-4 text-lg">
              E-posten du har skrevet er ikke en del av Fikse kontoravtale. Sjekk at du
              har skrevet den riktig, eller kontakt oss på hei@fikse.co
            </div>
          )}
          <input
            type="text"
            placeholder="Skriv inn din jobb epost her"
            className={`text-md block w-full border-t border-black bg-${notValidOfficeEmail ? "fikseRed" : "fikseBeige"} p-4 px-6`}
            value={officeEmail}
            onChange={(e) => setOfficeEmail(e.target.value)}
          />
          <button
            type="button"
            onClick={() => {
              if (checkOfficeEmail()) {
                setLogginModal(false);
                validateOfficeEmailAndProcceed();
              } else {
                setNotValidOfficeEmail(true);
              }
            }}
            className={`mt-auto flex w-full flex-row justify-between rounded-none border-b border-t border-black bg-fikseGreen px-6 py-4 text-left text-lg`}
          >
            Forsett
            <ChevronRightIcon className="mt-1 h-5 w-5 text-gray-500" aria-hidden="true" />
          </button>
        </div>
      </ModalTile>
    </>
  );
};
