import React, { useEffect, useRef, useState } from "react";

import { on } from "events";
import { doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";
import { send } from "process";
import { useTranslation } from "react-i18next";

import { getFormalName, sendSMS } from "../contact";

const db = getFirestore();

export const cancelOrderInFirestore = async (
  orderType: string,
  orderId: string,
  t: any
) => {
  try {
    const orderRef = doc(db, orderType, orderId);

    // Get the current document
    const orderSnapshot = await getDoc(orderRef);

    if (orderSnapshot.exists()) {
      const currentOrderData = orderSnapshot.data();

      // Add the new activity to the current list of activities
      const newActivity = {
        id: currentOrderData.activity ? currentOrderData.activity.length + 1 : -1,
        content: "orderCancelled",
        date: new Date().toLocaleDateString(),
        dateTime: new Date().toISOString(),
      };

      const updatedActivities = currentOrderData.activity
        ? [...currentOrderData.activity, newActivity]
        : [newActivity]; // If no activity array exists, create one

      // Update the document with the new activity
      await updateDoc(orderRef, {
        activity: updatedActivities, // Update the activity field
      });

      console.log("Order updated successfully with cancellation activity");
      const phoneNumber = currentOrderData.contactInfo.phone;
      const username = getFormalName(currentOrderData.contactInfo.name);
      const userEmail = currentOrderData.email;
      let userDomain = "";

      if (
        userEmail.endsWith("oslo.kommune.no") ||
        userEmail.endsWith("osloskolen.no") ||
        userEmail.endsWith("fikse.co") ||
        userEmail.endsWith("ntnu.no") ||
        userEmail.endsWith("egms.no")
      ) {
        userDomain = "https://oslo.kommune.fikse.co/orders/workwear/";
      } else {
        userDomain = "https://fikse.co/orders/workwear/";
      }
      const message =
        t("hello") +
        " " +
        username +
        ",\n\n" +
        t("cancelMessage") +
        "\n" +
        userDomain +
        orderId;

      await sendSMS(phoneNumber, orderId, message);
      console.log("SMS sent to customer, order cancelled", phoneNumber, orderId);
    } else {
      console.log("Order does not exist");
    }
  } catch (error) {
    console.error("Error updating order:", error);
  }
};

async function saveTailorsNote(newTailorsNote: string, orderId: string) {
  try {
    const orderRef = doc(db, "workwearOrders", orderId);
    const orderSnapshot = await getDoc(orderRef);

    if (orderSnapshot.exists()) {
      const currentOrderData = orderSnapshot.data();
      await updateDoc(orderRef, {
        tailorsNote: newTailorsNote,
      });
      console.log("Tailor's note updated successfully");
    } else {
      console.log("Order does not exist");
    }
  } catch (error) {
    console.error("Error updating order:", error);
  }
}
async function deviateOrder(
  orderType: string,
  orderId: string,
  deviationPrice: number,
  t: any
) {
  try {
    const orderRef = doc(db, orderType, orderId);
    const orderSnapshot = await getDoc(orderRef);

    if (orderSnapshot.exists()) {
      const currentOrderData = orderSnapshot.data();
      console.log("Current order data", currentOrderData);

      // Add the new activity to the current list of activities
      const newActivity = {
        id: currentOrderData.activity ? currentOrderData.activity.length + 1 : -1,
        content: "orderDeviation",
        date: new Date().toLocaleDateString(),
        dateTime: new Date().toISOString(),
        deviationPrice: deviationPrice,
      };

      const updatedActivities = currentOrderData.activity
        ? [...currentOrderData.activity, newActivity]
        : [newActivity]; // If no activity array exists, create one

      // Update the document with the new activity
      await updateDoc(orderRef, {
        activity: updatedActivities, // Update the activity field
      });

      console.log("Order updated successfully with deviation activity");
      const phoneNumber = currentOrderData.contactInfo.phone;
      const username = getFormalName(currentOrderData.contactInfo.name);
      const userEmail = currentOrderData.email;
      let userDomain = "";

      if (
        userEmail.endsWith("oslo.kommune.no") ||
        userEmail.endsWith("osloskolen.no") ||
        userEmail.endsWith("fikse.co") ||
        userEmail.endsWith("ntnu.no") ||
        userEmail.endsWith("egms.no")
      ) {
        userDomain = "https://oslo.kommune.fikse.co/orders/workwear/";
      } else {
        userDomain = "https://fikse.co/orders/workwear/";
      }
      const message =
        t("hello") +
        " " +
        username +
        ",\n\n" +
        t("deviationMessage") +
        "\n\n" +
        userDomain +
        orderId;

      await sendSMS(phoneNumber, orderId, message);
      console.log("SMS sent to customer, order deviated", phoneNumber, orderId);
    } else {
      console.log("Order does not exist");
    }
  } catch (error) {
    console.error("Error updating order:", error);
  }
}

interface AvvikIOrdreProps {
  orderType: string;
  orderId: string;
  onButtonClick: () => void;
}

const AvvikIOrdre: React.FC<AvvikIOrdreProps> = ({
  orderType,
  orderId,
  onButtonClick,
}) => {
  console.log(orderType, orderId);
  console.log("AvvikIOrdre");

  const [localTailorsNote, setLocalTailorsNote] = useState<string>("");
  const [deviationPrice, setDeviationPrice] = useState<number>(0);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { t } = useTranslation();

  const handleCancelOrder = async (orderType: string, orderId: string, t: any) => {
    const confirmed = window.confirm(t("cancleConfirmation"));
    if (confirmed) {
      console.log("Order cancelled");
      onButtonClick();
      await saveTailorsNote(localTailorsNote, orderId);
      await cancelOrderInFirestore(orderType, orderId, t);
      window.location.reload();
    } else {
      console.log("Order not cancelled");
    }
  };

  const handleDeviation = async (
    orderType: string,
    orderId: string,
    deviationPrice: number,
    t: any
  ) => {
    const confirmed = window.confirm(t("deviationConfirmation"));
    if (confirmed) {
      console.log("Deviation accepted");
      onButtonClick();
      await saveTailorsNote(localTailorsNote, orderId);
      await deviateOrder(orderType, orderId, deviationPrice, t);
      window.location.reload();
    } else {
      console.log("Deviation not accepted");
    }
  };

  const handleInput = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    handleInput();
  }, [localTailorsNote]);

  useEffect(() => {
    const fetchOrderData = async () => {
      const db = getFirestore();
      const orderRef = doc(db, orderType, orderId);
      const orderSnap = await getDoc(orderRef);

      if (orderSnap.exists()) {
        setLocalTailorsNote(orderSnap.data().tailorsNote);
      } else {
        console.log("Order does not exist");
      }
    };

    fetchOrderData();
  }, []);

  return (
    <div className="mb-20 text-base">
      <button
        className="mb-14 w-full border-b border-t border-black bg-fikseRed p-4 text-left"
        onClick={() => {
          handleCancelOrder(orderType, orderId, t);
        }}
      >
        {t("cancelOrder")}
      </button>
      <div className="flex w-full border-b border-t border-black">
        <button
          className="w-2/3 border-r border-black bg-fikseBlue p-4 text-left"
          onClick={() => {
            handleDeviation(orderType, orderId, deviationPrice, t);
          }}
        >
          {t("deviationButton")}
        </button>
        <input
          type="number"
          className="w-1/3 bg-fikseBeige p-4 text-left"
          placeholder={t("enterPrice")}
          onChange={(e) => setDeviationPrice(Number(e.target.value))}
        />
      </div>

      <textarea
        ref={textareaRef}
        className="mt-14 w-full border-b border-t border-black bg-fikseBeige p-4"
        placeholder={t("deviationDescription")}
        value={localTailorsNote}
        onChange={(e) => setLocalTailorsNote(e.target.value)}
        onInput={handleInput}
        rows={1}
      />
      <button
        className="w-full border-b border-black bg-fikseBlue p-4 text-left"
        onClick={() => saveTailorsNote(localTailorsNote, orderId)}
      >
        {t("save")}
      </button>
    </div>
  );
};
export default AvvikIOrdre;
