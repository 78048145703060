import { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebase';

interface AddServiceCategoryModalWithCodeProps {
  onClose: () => void;
  onSave: (newServiceCategory: any) => void;
}

export const AddServiceCategoryModalWithCode = ({
  onClose,
  onSave,
}: AddServiceCategoryModalWithCodeProps) => {
  const [categoryName, setCategoryName] = useState('');

  const services = [
    {
      description: "Et lite hull",
      fix_type: "Liten fiks",
      id: "11111",
      item: "Topp",
      placeholder: "Beskriv plasseringen av hullet. Lapping eller sying?",
      price_nok: 199,
      problem: "Hull",
      work: ""
    },
    {
      description: "For hull i strikkede topper eller flere hull. Lapping eller sying?",
      fix_type: "Stor fiks",
      id: "11121",
      item: "Topp",
      placeholder: "Beskriv plasseringen av hullet, og om du har andre preferanser",
      price_nok: 349,
      problem: "Hull",
      work: ""
    },
    {
      description: "Kun eksisterende stoff",
      fix_type: "Liten fiks",
      id: "11312",
      item: "Topp",
      placeholder: "Hvor mange cm skal endres?",
      price_nok: 349,
      problem: "Legg inn eller ut",
      work: ""
    },
    {
      description: "Ekstra stoff",
      fix_type: "Stor fiks",
      id: "11322",
      item: "Topp",
      placeholder: "Hvor mange cm skal endres?",
      price_nok: 549,
      problem: "Legg inn eller ut",
      work: ""
    },
    {
      description: "For topper med ett lag som skal forkortes eller forlenges",
      fix_type: "Liten fiks",
      id: "11412",
      item: "Topp",
      placeholder: "Hvor mange cm skal endres, og ønsker du original søm?",
      price_nok: 369,
      problem: "Forkorte eller forlenge",
      work: "Topp"
    },
    {
      description: "For topper med flere lag stoff som skal forkortes eller forlenges, eller forlenges med ekstra stoff",
      fix_type: "Stor fiks",
      id: "11422",
      item: "Topp",
      placeholder: "Hvor mange cm skal endres, og ønsker du original søm?",
      price_nok: 599,
      problem: "Forkorte eller forlenge",
      work: "Topp"
    },
    {
      description: "Enkeltlags ermer som skal forkortes eller forlenges",
      fix_type: "Liten fiks",
      id: "11412",
      item: "Topp",
      placeholder: "Hvor mange cm skal endres, og ønsker du original søm?",
      price_nok: 369,
      problem: "Forkorte eller forlenge",
      work: "Ermer"
    },
    {
      description: "Flere lag ermer som skal forkortes eller forlenges, eller forlenges med ekstra stoff",
      fix_type: "Stor fiks",
      id: "11422",
      item: "Topp",
      placeholder: "Hvor mange cm skal endres, og ønsker du original søm?",
      price_nok: 599,
      problem: "Forkorte eller forlenge",
      work: "Ermer"
    },
    {
      description: "Glidelåser opptil 15 cm",
      fix_type: "Liten fiks",
      id: "11611",
      item: "Topp",
      placeholder: "Beskriv fargen på glidelåsen, og om du har andre preferanser",
      price_nok: 299,
      problem: "Glidelås",
      work: ""
    },
    {
      description: "Glidelåser lengre enn 15 cm",
      fix_type: "Stor fiks",
      id: "11621",
      item: "Topp",
      placeholder: "Beskriv fargen på glidelåsen, og om du har andre preferanser",
      price_nok: 549,
      problem: "Glidelås",
      work: ""
    },
    {
      description: "T-skjorte eller singlet",
      fix_type: "Liten fiks",
      id: "11711",
      item: "Topp",
      placeholder: "Beskriv hvor flekken er, og om du har andre preferanser",
      price_nok: 229,
      problem: "Rens",
      work: ""
    },
    {
      description: "Bluse med ermer eller genser",
      fix_type: "Stor fiks",
      id: "11721",
      item: "Topp",
      placeholder: "Beskriv hvor flekken er, og om du har andre preferanser",
      price_nok: 299,
      problem: "Rens",
      work: ""
    },
    {
      description: "Liten sømåpning, f.eks. en løsnet tag",
      fix_type: "Liten fiks",
      id: "11211",
      item: "Topp",
      placeholder: "Beskriv plasseringen av sømmen, og om du har andre preferanser",
      price_nok: 99,
      problem: "Søm",
      work: ""
    },
    {
      description: "Større sømåpning",
      fix_type: "Stor fiks",
      id: "11221",
      item: "Topp",
      placeholder: "Beskriv plasseringen av sømmen, og om du har andre preferanser",
      price_nok: 299,
      problem: "Søm",
      work: ""
    },
    {
      description: "Festet eller erstattet en knapp",
      fix_type: "Liten fiks",
      id: "11511",
      item: "Topp",
      placeholder: "Beskriv plasseringen av problemet, og preferanser for erstatningsknappen",
      price_nok: 79,
      problem: "Knapp",
      work: ""
    },
    {
      description: "Festet eller erstattet opptil tre knapper. For flere, bestill to av denne tjenesten",
      fix_type: "Stor fiks",
      id: "11521",
      item: "Topp",
      placeholder: "Beskriv plasseringen av problemet, og preferanser for erstatningsknappen",
      price_nok: 149,
      problem: "Knapp",
      work: ""
    },
    {
      description: "Små hull",
      fix_type: "Liten fiks",
      id: "12111",
      item: "Underdel",
      placeholder: "Beskriv plasseringen av hullet. Lapping eller sying?",
      price_nok: 199,
      problem: "Hull",
      work: ""
    },
    {
      description: "For bukser med fôr eller flere hull",
      fix_type: "Stor fiks",
      id: "12121",
      item: "Underdel",
      placeholder: "Beskriv plasseringen av hullet. Lapping eller sying?",
      price_nok: 399,
      problem: "Hull",
      work: ""
    },
    {
      description: "Liten sømåpning, f.eks. en løsnet tag",
      fix_type: "Liten fiks",
      id: "12211",
      item: "Underdel",
      placeholder: "Beskriv plasseringen av sømmen, og om du har andre preferanser",
      price_nok: 99,
      problem: "Søm",
      work: ""
    },
    {
      description: "Større sømåpning",
      fix_type: "Stor fiks",
      id: "12221",
      item: "Underdel",
      placeholder: "Beskriv plasseringen av sømmen, og om du har andre preferanser",
      price_nok: 299,
      problem: "Søm",
      work: ""
    },
    {
      description: "Kun eksisterende stoff",
      fix_type: "Liten fiks",
      id: "12312",
      item: "Underdel",
      placeholder: "Hvor mange cm skal endres?",
      price_nok: 349,
      problem: "Legg inn eller ut",
      work: "Midje"
    },
    {
      description: "Ta ut med ekstra stoff",
      fix_type: "Stor fiks",
      id: "12322",
      item: "Underdel",
      placeholder: "Hvor mange cm skal endres?",
      price_nok: 499,
      problem: "Legg inn eller ut",
      work: "Midje"
    },
    {
      description: "Kun eksisterende stoff",
      fix_type: "Liten fiks",
      id: "12312",
      item: "Underdel",
      placeholder: "Hvor mange cm skal endres?",
      price_nok: 299,
      problem: "Legg inn eller ut",
      work: "Ben"
    },
    {
      description: "Ta ut med ekstra stoff",
      fix_type: "Stor fiks",
      id: "12322",
      item: "Underdel",
      placeholder: "Hvor mange cm skal endres?",
      price_nok: 449,
      problem: "Legg inn eller ut",
      work: "Ben"
    },
    {
      description: "Festet eller erstattet en knapp",
      fix_type: "Liten fiks",
      id: "12511",
      item: "Underdel",
      placeholder: "Beskriv plasseringen av problemet, og preferanser for erstatningsknappen",
      price_nok: 79,
      problem: "Knapp",
      work: ""
    },
    {
      description: "Festet eller erstattet opptil tre knapper. For flere, bestill to av denne tjenesten",
      fix_type: "Stor fiks",
      id: "12521",
      item: "Underdel",
      placeholder: "Beskriv plasseringen av problemet, og preferanser for erstatningsknappen",
      price_nok: 149,
      problem: "Knapp",
      work: ""
    },
    {
      description: "For plagg med ett lag",
      fix_type: "Liten fiks",
      id: "12412",
      item: "Underdel",
      placeholder: "Hvor mange cm skal endres, og ønsker du original søm?",
      price_nok: 269,
      problem: "Forkorte eller forlenge",
      work: ""
    },
    {
      description: "For plagg med flere lag, eller ved bruk av ekstra stoff",
      fix_type: "Stor fiks",
      id: "12422",
      item: "Underdel",
      placeholder: "Hvor mange cm skal endres, og ønsker du original søm?",
      price_nok: 399,
      problem: "Forkorte eller forlenge",
      work: ""
    },
    {
      description: "For glidelåser opptil 15 cm",
      fix_type: "Liten fiks",
      id: "12611",
      item: "Underdel",
      placeholder: "Beskriv fargen på glidelåsen, og om du har andre preferanser",
      price_nok: 329,
      problem: "Glidelås",
      work: ""
    },
    {
      description: "For glidelåser lengre enn 15 cm",
      fix_type: "Stor fiks",
      id: "12621",
      item: "Underdel",
      placeholder: "Beskriv fargen på glidelåsen, og om du har andre preferanser",
      price_nok: 429,
      problem: "Glidelås",
      work: ""
    },
    {
      description: "Rengjøring av de fleste typer bukser. Sjekk vaskelappen for å være sikker.",
      fix_type: "Standard",
      id: "12711",
      item: "Underdel",
      placeholder: "Beskriv hvor flekken er, og om du har andre preferanser",
      price_nok: 299,
      problem: "Rens",
      work: ""
    },
    {
      description: "Usynlig reparasjon av et lite hull i vinterjakke, dunjakke eller parkas",
      fix_type: "Liten fiks",
      id: "13111",
      item: "Jakke",
      placeholder: "Beskriv plasseringen av hullet, og om du har andre preferanser",
      price_nok: 499,
      problem: "Hull",
      work: "Vinterjakke"
    },
    {
      description: "Usynlig reparasjon av flere hull i vinterjakke, dunjakke eller parkas",
      fix_type: "Stor fiks",
      id: "13121",
      item: "Jakke",
      placeholder: "Beskriv plasseringen av hullet, og om du har andre preferanser",
      price_nok: 1349,
      problem: "Hull",
      work: "Vinterjakke"
    },
    {
      description: "Lite hull",
      fix_type: "Liten fiks",
      id: "13111",
      item: "Jakke",
      placeholder: "Beskriv plasseringen av hullet, og om du har andre preferanser",
      price_nok: 299,
      problem: "Hull",
      work: "Lett jakke"
    },
    {
      description: "Flere hull",
      fix_type: "Stor fiks",
      id: "13121",
      item: "Jakke",
      placeholder: "Beskriv plasseringen av hullet, og om du har andre preferanser",
      price_nok: 499,
      problem: "Hull",
      work: "Lett jakke"
    },
    {
      description: "Liten sømåpning, f.eks. en løsnet tag",
      fix_type: "Liten fiks",
      id: "13211",
      item: "Jakke",
      placeholder: "Beskriv plasseringen av sømmen, og om du har andre preferanser",
      price_nok: 99,
      problem: "Søm",
      work: ""
    },
    {
      description: "Større sømåpning",
      fix_type: "Stor fiks",
      id: "13221",
      item: "Jakke",
      placeholder: "Beskriv plasseringen av sømmen, og om du har andre preferanser",
      price_nok: 299,
      problem: "Søm",
      work: ""
    },
    {
      description: "Kun eksisterende stoff",
      fix_type: "Liten fiks",
      id: "13312",
      item: "Jakke",
      placeholder: "Hvor mange cm skal endres?",
      price_nok: 699,
      problem: "Legg inn eller ut",
      work: ""
    },
    {
      description: "Ta ut med ekstra stoff",
      fix_type: "Stor fiks",
      id: "13322",
      item: "Jakke",
      placeholder: "Hvor mange cm skal endres?",
      price_nok: 899,
      problem: "Legg inn eller ut",
      work: ""
    },
    {
      description: "Festet eller erstattet en knapp",
      fix_type: "Liten fiks",
      id: "13511",
      item: "Jakke",
      placeholder: "Beskriv plasseringen av problemet, og preferanser for erstatningsknappen",
      price_nok: 79,
      problem: "Knapp",
      work: ""
    },
    {
      description: "Festet eller erstattet opptil tre knapper. For flere, bestill to av denne tjenesten",
      fix_type: "Stor fiks",
      id: "13521",
      item: "Jakke",
      placeholder: "Beskriv plasseringen av problemet, og preferanser for erstatningsknappen",
      price_nok: 149,
      problem: "Knapp",
      work: ""
    },
    {
      description: "For plagg uten fôr",
      fix_type: "Liten fiks",
      id: "13412",
      item: "Jakke",
      placeholder: "Hvor mange cm skal endres?",
      price_nok: 369,
      problem: "Forkorte eller forlenge",
      work: "Erme"
    },
    {
      description: "For plagg med fôr eller forlengelse med ekstra stoff",
      fix_type: "Stor fiks",
      id: "13422",
      item: "Jakke",
      placeholder: "Hvor mange cm skal endres?",
      price_nok: 599,
      problem: "Forkorte eller forlenge",
      work: "Erme"
    },
    {
      description: "For plagg som skal forkortes i lengden",
      fix_type: "Liten fiks",
      id: "13412",
      item: "Jakke",
      placeholder: "Hvor mange cm skal endres?",
      price_nok: 369,
      problem: "Forkorte eller forlenge",
      work: "Jakke"
    },
    {
      description: "For plagg som skal forkortes i lengden med ekstra stoff",
      fix_type: "Stor fiks",
      id: "13422",
      item: "Jakke",
      placeholder: "Hvor mange cm skal endres?",
      price_nok: 599,
      problem: "Forkorte eller forlenge",
      work: "Jakke"
    },
    {
      description: "For glidelåser opptil 40 cm",
      fix_type: "Liten fiks",
      id: "13611",
      item: "Jakke",
      placeholder: "Beskriv fargen på glidelåsen, og om du har andre preferanser",
      price_nok: 449,
      problem: "Glidelås",
      work: ""
    },
    {
      description: "For glidelåser lengre enn 40 cm",
      fix_type: "Stor fiks",
      id: "13621",
      item: "Jakke",
      placeholder: "Beskriv fargen på glidelåsen, og om du har andre preferanser",
      price_nok: 799,
      problem: "Glidelås",
      work: ""
    },
    {
      description: "Kort jakke (Merk: Ikke dunjakke)",
      fix_type: "Liten fiks",
      id: "13711",
      item: "Jakke",
      placeholder: "Beskriv hvor flekken er, og om du har andre preferanser",
      price_nok: 449,
      problem: "Rens",
      work: ""
    },
    {
      description: "Dunjakke, lang jakke eller frakk",
      fix_type: "Stor fiks",
      id: "13721",
      item: "Jakke",
      placeholder: "Beskriv hvor flekken er, og om du har andre preferanser",
      price_nok: 649,
      problem: "Rens",
      work: ""
    },
    {
      description: "Impregneringstjeneste for jakker",
      fix_type: "Standard",
      id: "13111",
      item: "Jakke",
      placeholder: "",
      price_nok: null,
      problem: "Impregnering",
      work: ""
    },
    {
      description: "Et hull",
      fix_type: "Liten fiks",
      id: "14111",
      item: "Kjoler og skjørt (og jumpsuits)",
      placeholder: "Beskriv plasseringen av hullet, og om du har andre preferanser",
      price_nok: 199,
      problem: "Hull",
      work: ""
    },
    {
      description: "For kjoler med fôr eller flere hull",
      fix_type: "Stor fiks",
      id: "14121",
      item: "Kjoler og skjørt (og jumpsuits)",
      placeholder: "Beskriv plasseringen av hullet, og om du har andre preferanser",
      price_nok: 399,
      problem: "Hull",
      work: ""
    },
    {
      description: "Kun eksisterende stoff for skuldre/bryst",
      fix_type: "Liten fiks",
      id: "14312",
      item: "Kjoler og skjørt (og jumpsuits)",
      placeholder: "Hvor mange cm skal endres?",
      price_nok: 299,
      problem: "Legg inn eller ut",
      work: "Øvre del"
    },
    {
      description: "Ta ut med ekstra stoff for skuldre/bryst",
      fix_type: "Stor fiks",
      id: "14322",
      item: "Kjoler og skjørt (og jumpsuits)",
      placeholder: "Hvor mange cm skal endres?",
      price_nok: 499,
      problem: "Legg inn eller ut",
      work: "Øvre del"
    },
    {
      description: "Enkelt lag med kun eksisterende stoff",
      fix_type: "Liten fiks",
      id: "14312",
      item: "Kjoler og skjørt (og jumpsuits)",
      placeholder: "Hvor mange cm skal endres?",
      price_nok: 499,
      problem: "Legg inn eller ut",
      work: "Midje"
    },
    {
      description: "Flere lag eller ta ut med ekstra stoff",
      fix_type: "Stor fiks",
      id: "14322",
      item: "Kjoler og skjørt (og jumpsuits)",
      placeholder: "Hvor mange cm skal endres?",
      price_nok: 899,
      problem: "Legg inn eller ut",
      work: "Midje"
    },
    {
      description: "Kun eksisterende stoff",
      fix_type: "Liten fiks",
      id: "14312",
      item: "Kjoler og skjørt (og jumpsuits)",
      placeholder: "Hvor mange cm skal endres?",
      price_nok: 369,
      problem: "Legg inn eller ut",
      work: "Ermer"
    },
    {
      description: "Ta ut med ekstra stoff",
      fix_type: "Stor fiks",
      id: "14322",
      item: "Kjoler og skjørt (og jumpsuits)",
      placeholder: "Hvor mange cm skal endres?",
      price_nok: 599,
      problem: "Legg inn eller ut",
      work: "Ermer"
    },
    {
      description: "Festet eller erstattet en knapp",
      fix_type: "Liten fiks",
      id: "14511",
      item: "Kjoler og skjørt (og jumpsuits)",
      placeholder: "Beskriv plasseringen av problemet, og preferanser for erstatningsknappen",
      price_nok: 79,
      problem: "Knapp",
      work: ""
    },
    {
      description: "Festet eller erstattet opptil tre knapper. For flere, bestill to av denne tjenesten",
      fix_type: "Stor fiks",
      id: "14521",
      item: "Kjoler og skjørt (og jumpsuits)",
      placeholder: "Beskriv plasseringen av problemet, og preferanser for erstatningsknappen",
      price_nok: 149,
      problem: "Knapp",
      work: ""
    },
    {
      description: "For plagg uten fôr",
      fix_type: "Liten fiks",
      id: "14412",
      item: "Kjoler og skjørt (og jumpsuits)",
      placeholder: "Hvor mange cm skal endres, og ønsker du original søm?",
      price_nok: 369,
      problem: "Forkorte eller forlenge",
      work: "Erme"
    },
    {
      description: "For plagg med fôr",
      fix_type: "Stor fiks",
      id: "14422",
      item: "Kjoler og skjørt (og jumpsuits)",
      placeholder: "Hvor mange cm skal endres, og ønsker du original søm?",
      price_nok: 599,
      problem: "Forkorte eller forlenge",
      work: "Erme"
    },
    {
      description: "Enkelt lag med kun eksisterende stoff",
      fix_type: "Liten fiks",
      id: "14412",
      item: "Kjoler og skjørt (og jumpsuits)",
      placeholder: "Hvor mange cm skal endres, og ønsker du original søm?",
      price_nok: 399,
      problem: "Forkorte eller forlenge",
      work: "Kjole/skjørt"
    },
    {
      description: "Flere lag eller ta ut med ekstra stoff",
      fix_type: "Stor fiks",
      id: "14422",
      item: "Kjoler og skjørt (og jumpsuits)",
      placeholder: "Hvor mange cm skal endres, og ønsker du original søm?",
      price_nok: 799,
      problem: "Forkorte eller forlenge",
      work: "Kjole/skjørt"
    },
    {
      description: "For glidelåser opptil 20 cm",
      fix_type: "Liten fiks",
      id: "14611",
      item: "Kjoler og skjørt (og jumpsuits)",
      placeholder: "Beskriv fargen på glidelåsen, og om du har andre preferanser",
      price_nok: 329,
      problem: "Glidelås",
      work: ""
    },
    {
      description: "For glidelåser lengre enn 20 cm",
      fix_type: "Stor fiks",
      id: "14621",
      item: "Kjoler og skjørt (og jumpsuits)",
      placeholder: "Beskriv fargen på glidelåsen, og om du har andre preferanser",
      price_nok: 549,
      problem: "Glidelås",
      work: ""
    },
    {
      description: "Kort skjørt",
      fix_type: "Liten fiks",
      id: "14711",
      item: "Kjoler og skjørt (og jumpsuits)",
      placeholder: "Beskriv hvor flekken er, og om du har andre preferanser",
      price_nok: 299,
      problem: "Rens",
      work: "Skjørt"
    },
    {
      description: "Langt skjørt",
      fix_type: "Stor fiks",
      id: "14721",
      item: "Kjoler og skjørt (og jumpsuits)",
      placeholder: "Beskriv hvor flekken er, og om du har andre preferanser",
      price_nok: 449,
      problem: "Rens",
      work: "Skjørt"
    },
    {
      description: "Kort eller enkel kjole",
      fix_type: "Liten fiks",
      id: "14711",
      item: "Kjoler og skjørt (og jumpsuits)",
      placeholder: "Beskriv hvor flekken er, og om du har andre preferanser",
      price_nok: 499,
      problem: "Rens",
      work: "Kjole"
    },
    {
      description: "Aftenkjole eller lang kjole",
      fix_type: "Stor fiks",
      id: "14721",
      item: "Kjoler og skjørt (og jumpsuits)",
      placeholder: "Beskriv hvor flekken er, og om du har andre preferanser",
      price_nok: 1149,
      problem: "Rens",
      work: "Kjole"
    },
    {
      description: "Liten sømåpning, f.eks. en løsnet tag",
      fix_type: "Liten fiks",
      id: "14211",
      item: "Kjoler og skjørt (og jumpsuits)",
      placeholder: "Beskriv plasseringen av sømmen, og om du har andre preferanser",
      price_nok: 99,
      problem: "Søm",
      work: ""
    },
    {
      description: "Større sømåpning",
      fix_type: "Stor fiks",
      id: "14221",
      item: "Kjoler og skjørt (og jumpsuits)",
      placeholder: "Beskriv plasseringen av sømmen, og om du har andre preferanser",
      price_nok: 299,
      problem: "Søm",
      work: ""
    },
    {
      description: "Hele dressen",
      fix_type: "Standard",
      id: "15711",
      item: "Dresser",
      placeholder: "Beskriv hvor flekken er, og om du har andre preferanser",
      price_nok: 579,
      problem: "Rens",
      work: "Dress"
    },
    {
      description: "",
      fix_type: "Standard",
      id: "15721",
      item: "Dresser",
      placeholder: "Beskriv hvor flekken er, og om du har andre preferanser",
      price_nok: 319,
      problem: "Rens",
      work: "Blazer"
    },
    {
      description: "",
      fix_type: "Standard",
      id: "15711",
      item: "Dresser",
      placeholder: "Beskriv hvor flekken er, og om du har andre preferanser",
      price_nok: 299,
      problem: "Rens",
      work: "Bukser"
    },
    {
      description: "",
      fix_type: "Standard",
      id: "15721",
      item: "Dresser",
      placeholder: "Beskriv hvor flekken er, og om du har andre preferanser",
      price_nok: 199,
      problem: "Rens",
      work: "Vest"
    },
    {
      description: "Vask av en skjorte",
      fix_type: "Liten fiks",
      id: "15711",
      item: "Dresser",
      placeholder: "Beskriv hvor flekken er, og om du har andre preferanser",
      price_nok: 79,
      problem: "Rens",
      work: "Skjorte"
    },
    {
      description: "Vask av tre skjorter",
      fix_type: "Stor fiks",
      id: "15721",
      item: "Dresser",
      placeholder: "Beskriv hvor flekken er, og om du har andre preferanser",
      price_nok: 199,
      problem: "Rens",
      work: "Skjorte"
    },
    {
      description: "Et hull",
      fix_type: "Liten fiks",
      id: "15111",
      item: "Dresser",
      placeholder: "Beskriv plasseringen av hullet, og om du har andre preferanser",
      price_nok: 199,
      problem: "Hull",
      work: ""
    },
    {
      description: "Flere hull",
      fix_type: "Stor fiks",
      id: "15121",
      item: "Dresser",
      placeholder: "Beskriv plasseringen av hullet, og om du har andre preferanser",
      price_nok: 349,
      problem: "Hull",
      work: ""
    },
    {
      description: "Liten sømåpning, f.eks. en løsnet tag",
      fix_type: "Liten fiks",
      id: "15211",
      item: "Dresser",
      placeholder: "Beskriv plasseringen av sømmen, og om du har andre preferanser",
      price_nok: 99,
      problem: "Søm",
      work: ""
    },
    {
      description: "Større sømåpning",
      fix_type: "Stor fiks",
      id: "15221",
      item: "Dresser",
      placeholder: "Beskriv plasseringen av sømmen, og om du har andre preferanser",
      price_nok: 299,
      problem: "Søm",
      work: ""
    },
    {
      description: "Kun eksisterende stoff",
      fix_type: "Liten fiks",
      id: "15312",
      item: "Dresser",
      placeholder: "Hvor mange cm skal endres?",
      price_nok: 299,
      problem: "Legg inn eller ut",
      work: "Arm"
    },
    {
      description: "Ta ut med ekstra stoff",
      fix_type: "Stor fiks",
      id: "15322",
      item: "Dresser",
      placeholder: "Hvor mange cm skal endres?",
      price_nok: 449,
      problem: "Legg inn eller ut",
      work: "Arm"
    },
    {
      description: "Kun eksisterende stoff (Blazer eller underdel)",
      fix_type: "Liten fiks",
      id: "15312",
      item: "Dresser",
      placeholder: "Hvor mange cm skal endres?",
      price_nok: 349,
      problem: "Legg inn eller ut",
      work: "Midje"
    },
    {
      description: "Ta ut med ekstra stoff (Blazer eller underdel)",
      fix_type: "Stor fiks",
      id: "15322",
      item: "Dresser",
      placeholder: "Hvor mange cm skal endres?",
      price_nok: 499,
      problem: "Legg inn eller ut",
      work: "Midje"
    },
    {
      description: "Kun eksisterende stoff",
      fix_type: "Liten fiks",
      id: "15312",
      item: "Dresser",
      placeholder: "Hvor mange cm skal endres?",
      price_nok: 299,
      problem: "Legg inn eller ut",
      work: "Innvendig søm bukse"
    },
    {
      description: "Ta ut med ekstra stoff",
      fix_type: "Stor fiks",
      id: "15322",
      item: "Dresser",
      placeholder: "Hvor mange cm skal endres?",
      price_nok: 449,
      problem: "Legg inn eller ut",
      work: "Innvendig søm bukse"
    },
    {
      description: "Festet eller erstattet en knapp",
      fix_type: "Liten fiks",
      id: "15511",
      item: "Dresser",
      placeholder: "Beskriv plasseringen av problemet, og preferanser for erstatningsknappen",
      price_nok: 79,
      problem: "Knapp",
      work: ""
    },
    {
      description: "Festet eller erstattet opptil tre knapper. For flere, bestill to av denne tjenesten",
      fix_type: "Stor fiks",
      id: "15521",
      item: "Dresser",
      placeholder: "Beskriv plasseringen av problemet, og preferanser for erstatningsknappen",
      price_nok: 149,
      problem: "Knapp",
      work: ""
    },
    {
      description: "Enkelt lag",
      fix_type: "Liten fiks",
      id: "15412",
      item: "Dresser",
      placeholder: "Hvor mange cm skal endres, og ønsker du original søm?",
      price_nok: 269,
      problem: "Forkorte eller forlenge",
      work: "Bukser"
    },
    {
      description: "Flere lag eller forlengelse med ekstra stoff",
      fix_type: "Stor fiks",
      id: "15422",
      item: "Dresser",
      placeholder: "Hvor mange cm skal endres, og ønsker du original søm?",
      price_nok: 399,
      problem: "Forkorte eller forlenge",
      work: "Bukser"
    },
    {
      description: "Kun eksisterende stoff",
      fix_type: "Liten fiks",
      id: "15412",
      item: "Dresser",
      placeholder: "Hvor mange cm skal endres, og ønsker du original søm?",
      price_nok: 599,
      problem: "Forkorte eller forlenge",
      work: "Ermer"
    },
    {
      description: "Forlengelse med ekstra stoff eller fra skuldrene",
      fix_type: "Stor fiks",
      id: "15422",
      item: "Dresser",
      placeholder: "Hvor mange cm skal endres, og ønsker du original søm?",
      price_nok: 1299,
      problem: "Forkorte eller forlenge",
      work: "Ermer"
    },
    {
      description: "Enkelt lag",
      fix_type: "Liten fiks",
      id: "15412",
      item: "Dresser",
      placeholder: "Hvor mange cm skal endres?",
      price_nok: 399,
      problem: "Forkorte eller forlenge",
      work: "Blazer"
    },
    {
      description: "Forlengelse med ekstra stoff",
      fix_type: "Stor fiks",
      id: "15422",
      item: "Dresser",
      placeholder: "Hvor mange cm skal endres?",
      price_nok: 599,
      problem: "Forkorte eller forlenge",
      work: "Blazer"
    },
    {
      description: "For glidelåser opptil 15 cm",
      fix_type: "Liten fiks",
      id: "15611",
      item: "Dresser",
      placeholder: "Beskriv fargen på glidelåsen, og om du har andre preferanser",
      price_nok: 329,
      problem: "Glidelås",
      work: ""
    },
    {
      description: "For glidelåser lengre enn 15 cm",
      fix_type: "Stor fiks",
      id: "15621",
      item: "Dresser",
      placeholder: "Beskriv fargen på glidelåsen, og om du har andre preferanser",
      price_nok: 429,
      problem: "Glidelås",
      work: ""
    }
  ];

  const handleSave = async () => {
    if (!categoryName || services.length === 0) return;

    // Legg til nytt dokument i Firestore-samlingen
    const newCategory = {
      name: categoryName,
      categories: services,
      createdAt: new Date(),
    };

    try {
      const docRef = await addDoc(collection(db, 'serviceCategories'), newCategory);
      onSave({ ...newCategory, id: docRef.id });
    } catch (e) {
      console.error('Error adding document: ', e);
    }

    onClose();
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-white p-6 rounded shadow-lg max-w-md w-full">
          <h2 className="text-xl font-semibold mb-4">Add Service Category</h2>
          <input
            type="text"
            placeholder="Category Name"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            className="border p-2 w-full mb-4"
          />
          <button
            onClick={handleSave}
            className="bg-blue-500 text-white py-2 px-4 rounded mr-2"
            disabled={!categoryName || services.length === 0}
          >
            Save
          </button>
          <button onClick={onClose} className="bg-gray-300 text-black py-2 px-4 rounded">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

