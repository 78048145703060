// components/OrderCardDetails.tsx
import React from "react";

import { useTranslation } from "react-i18next";

import { fallbackImageUrl } from "../../components/newOrder/OrderModal";
import { ActivityItem, Order, orderStates } from "../OrderPage";
import OrderCardDetails from "./OrderCardDetails";

const OrderLog: React.FC<{ order: Order; activity: any[] }> = ({ order, activity }) => {
  const { t } = useTranslation();

  const lastActivityWithOrderState = [...activity]
    .reverse()
    .find((event) => orderStates.includes(event.content));

  return (
    <div>
      <div
        className={`hidden ${
          order.orderInfo.some((info) => info.imageUrl !== fallbackImageUrl)
            ? "min-h-[calc(55vh-7rem)]"
            : ""
        } md:block`}
      >
        <OrderCardDetails order={order} />
      </div>

      <p className="border-b border-black p-4 pt-16 text-base">
        {t("workwearOrderPage.trackingLog")}{" "}
      </p>
      <ul role="list" className="-mb-8 min-h-[478px]">
        {activity.map((event, eventIdx) => {
          const message = event.content
            ? t(`workwearOrderPage.${event.content}`)
            : event.message;
          const timestamp = event.dateTime || event.timestamp;
          const formattedTime = `${new Date(timestamp).toLocaleDateString(
            "en-GB"
          )} ${new Date(timestamp).toLocaleTimeString("en-GB", {
            hour: "2-digit",
            minute: "2-digit",
          })}`;
          return (
            <li
              key={event.id || `${timestamp}-${eventIdx}`}
              className={`border-b border-black p-4 text-base ${
                eventIdx === activity.length - 1
                  ? "bg-fikseBeige text-black"
                  : "text-fikseGrey"
              }`}
            >
              <span className="flex justify-between">
                <span>{message}</span>
                <span className="ml-1">
                  <time dateTime={timestamp}>{formattedTime}</time>
                </span>
              </span>
            </li>
          );
        })}

        {orderStates
          .slice(orderStates.slice(1).indexOf(lastActivityWithOrderState?.content) + 2)
          .map((state, index) => (
            <li
              key={`future-state-${index}`}
              className="border-b border-black p-4 text-base text-fikseGrey"
            >
              {t(`workwearOrderPage.${state}`)}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default OrderLog;
