import React, { useEffect, useState } from "react";

import { collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import QRCode from "qrcode";
import { useNavigate } from "react-router-dom";

import { app, db, storage } from "../../firebase";
import { ReactComponent as CheckIcon } from "../../images/icons-fikse/checkmark.svg";
import { ReactComponent as ChevronDownIcon } from "../../images/icons-fikse/dropdown.svg";
import { getFormalName, sendSMS } from "../contact";

// Oppdatert `OrderInfo`-type basert på `RecognitionResult`
interface OrderInfo {
  id: string;
  item: string;
  problem: string;
  fix_type: string;
  description: string;
  price_nok: number;
  work: string;
  placeholder: string;
  imageUrl: string;
  customerComment: string;
}

interface ContactInfo {
  department: string;
  name: string;
  phone: string;
  address: string;
}

interface ApiResponse {
  firstName: string;
  middleName?: string;
  lastName: string;
  address: string;
  postalCode: string;
  city: string;
}

interface CheckoutB2CProps {
  garments: OrderInfo[];
  contactInfo: ContactInfo;
  onClose: () => void;
  onBackToCart: () => void;
}

const CheckoutB2C: React.FC<CheckoutB2CProps> = ({
  garments,
  contactInfo,
  onClose,
  onBackToCart,
}) => {
  console.log("render of CheckoutB2C");
  const [phoneNumber, setPhoneNumber] = useState(contactInfo.phone || "");
  const [deliveryMethod, setDeliveryMethod] = useState("Holzweiler");
  const [retrieveMethod, setRetrieveMethod] = useState("Holzweiler");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [receiveMarketing, setReceiveMarketing] = useState(false);
  const [marketingEmail, setMarketingEmail] = useState(localStorage.getItem("uid") || "");
  const [isFormValid, setIsFormValid] = useState(false);
  const [lookupData, setLookupData] = useState<ApiResponse | null>(null);
  const [lookupStatus, setLookupStatus] = useState("");
  const [fullName, setFullName] = useState(contactInfo.name || "");
  const [address, setAddress] = useState(contactInfo.address || "");
  const [uploading, setUploading] = useState(false);

  const [dropoffLocations, setDropoffLocations] = useState<string[]>([]);
  const [deliveryLocations, setDeliveryLocations] = useState<string[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    setIsFormValid(
      phoneNumber !== "" && address !== "" && acceptTerms && garments.length > 0
    );
  }, [phoneNumber, acceptTerms, garments, address]);

  useEffect(() => {
    if (phoneNumber.length === 8) {
      handleLookupClick();
    } else {
      setLookupData(null);
    }
  }, [phoneNumber]);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const docRef = doc(collection(db, "dynamicPages"), "locations");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();

          const currentUrl = window.location.href;
          if (currentUrl.includes("avtale.fikse.co")) {
            console.log("User is on sub.domain.no");
            console.log("domain: ", currentUrl);
            setDropoffLocations(data.locationsListOffice || []);
            setDeliveryLocations(data.locationsListOffice || []);
          } else {
            setDropoffLocations(data.locationsList || []);
            setDeliveryLocations(data.locationsList || []);
          }

          if (dropoffLocations.length > 0 && deliveryLocations.length > 0) {
            console.log("setting DM", dropoffLocations[0]);
            setDeliveryMethod(dropoffLocations[0]);
            setRetrieveMethod(dropoffLocations[0]);
          }
        } else {
          console.error("No such document in Firestore!");
        }
      } catch (error) {
        console.error("Error fetching locations: ", error);
      }
    };
    fetchLocations();
  }, []);

  const handlePaymentMethodClick = async (method: string, orderId: string) => {
    const functions = getFunctions(app, "europe-west1");

    try {
      console.log(`Processing payment with ${method} for order ID: ${orderId}`);

      if (method === "vipps") {
        const orderRef = doc(db, "b2cOrders", orderId);
        const orderSnap = await getDoc(orderRef);
        if (!orderSnap.exists()) {
          throw new Error("Order does not exist");
        }
        const orderData = orderSnap.data();
        console.log("Order data", orderData.contactInfo.phone);

        const createVippsCheckout = httpsCallable(
          functions,
          "createVippsCheckoutFiksePortal"
        );
        const result = await createVippsCheckout(orderData);

        // Ensure TypeScript understands the expected structure of the result
        if (result.data && typeof result.data === "object" && "url" in result.data) {
          const paymentUrl = (result.data as { url: string }).url;
          window.location.href = paymentUrl;
        } else {
          throw new Error("Unexpected response from Vipps checkout function");
        }
      } else {
        // Simulate a payment processing delay for other methods
        await new Promise((resolve) => setTimeout(resolve, 1000));

        // Update Firestore document to mark the order as paid
        const orderRef = doc(db, "b2cOrders", orderId);
        await updateDoc(orderRef, {
          paid: true,
        });

        console.log(`Order ${orderId} marked as paid using ${method}.`);
        console.log("Sending SMS to notify the customer...", phoneNumber);
        await sendSMS(
          phoneNumber,
          orderId,
          "Your order has been successfully paid for. We will notify you when it is ready for pickup."
        );
        // Trigger the payment success callback to update the UI
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      alert("An error occurred while processing the payment. Please try again.");
    }
  };

  const functions = getFunctions(undefined, "europe-west1");
  const fetchPhoneNumberData = httpsCallable<{ phone: string }, ApiResponse>(
    functions,
    "fetchPhoneNumberData"
  );

  const handleLookupClick = async () => {
    if (phoneNumber.length === 8) {
      setLookupStatus("Looking up...");
      try {
        const response = await fetchPhoneNumberData({ phone: phoneNumber });

        if (response.data && Object.keys(response.data).length > 0) {
          const data: ApiResponse = response.data;
          setLookupData(data);
          setFullName(
            `${data.firstName}${data.middleName ? " " + data.middleName : ""}${
              data.lastName ? " " + data.lastName : ""
            }`
          );
          setAddress(`${data.address}, ${data.postalCode} ${data.city}`);
          setLookupStatus("Lookup successful");
        } else {
          setLookupData(null);
          setFullName(""); // Clear the fields to show placeholders if no data
          setAddress("");
          setLookupStatus("No information found");
        }
      } catch (error) {
        console.error("Error fetching lookup data: ", error);
        setLookupStatus("Lookup failed");
      }
    } else {
      setLookupStatus("Phone number must be 8 digits");
    }
  };

  const calculateTotalPrice = (orderInfo: OrderInfo[]) => {
    return orderInfo.reduce((total, item) => total + item.price_nok, 0); // Bruker `price_nok` fra `RecognitionResult`
  };

  const uploadImagesAndGetUrls = async (orderInfo: OrderInfo[]) => {
    const uploadPromises = orderInfo.map(async (item, index) => {
      if (item.imageUrl.startsWith("data:image")) {
        const storageRef = ref(storage, `orders/${Date.now()}_${index}.jpg`);
        await uploadString(storageRef, item.imageUrl, "data_url");
        const url = await getDownloadURL(storageRef);
        return { ...item, imageUrl: url };
      }
      return item;
    });

    return Promise.all(uploadPromises);
  };

  const generateCustomOrderId = () => {
    const letters = Array.from({ length: 5 }, () =>
      String.fromCharCode(65 + Math.floor(Math.random() * 26))
    ).join("");

    const numbers = Array.from({ length: 6 }, () => Math.floor(Math.random() * 10)).join(
      ""
    );

    return `${letters}${numbers}B2C`; // Using "B2C" as the suffix
  };

  const handlePaymentClick = async () => {
    console.log("asdasdas", dropoffLocations);
    if (isFormValid) {
      try {
        setUploading(true);

        const sanitizedOrderInfo = await uploadImagesAndGetUrls(garments);

        const initialActivity = {
          id: 1,
          content: "orderStateCreated",
          date: new Date().toLocaleDateString(),
          dateTime: new Date().toISOString(), //Todo: Ved å fjerne denne går ikke bestillinger til vipps. fiks dette forløpende
          timestamp: new Date().toISOString(),
        };

        const customOrderId = generateCustomOrderId();

        const orderData = {
          id: customOrderId, // Include the generated order ID
          email: localStorage.getItem("uid"),
          orderInfo: sanitizedOrderInfo,
          contactInfo: {
            ...contactInfo,
            phone: phoneNumber,
            name: fullName,
            address,
          },
          date: new Date().toISOString(),
          totalPrice: `${calculateTotalPrice(sanitizedOrderInfo)} kr`,
          deliveryMethod,
          retrieveMethod,
          receiveMarketing,
          marketingEmail: receiveMarketing ? marketingEmail : null,
          activity: [initialActivity],
          orderCreationUrl: window.location.href,
        };

        console.log("Order dataSLEtT", orderData);
        const docRef = doc(db, "b2cOrders", customOrderId);
        await setDoc(docRef, orderData);

        const orderUrl = `https://fiksefirstapp.web.app/orders/b2c/${customOrderId}#scanned`;

        const qrCodeDataUrl = await QRCode.toDataURL(orderUrl);

        const qrCodeStorageRef = ref(storage, `qrcodes/${customOrderId}.png`);
        await uploadString(qrCodeStorageRef, qrCodeDataUrl, "data_url");
        const qrCodeUrl = await getDownloadURL(qrCodeStorageRef);

        await updateDoc(docRef, { qrCodeUrl });

        // HARDCODE workaround to just skip to vipps payment
        await handlePaymentMethodClick("vipps", customOrderId);

        localStorage.setItem("activeOrder", `b2c/${customOrderId}`);
        navigate(`/orders/b2c/${customOrderId}`);

        onClose();
      } catch (error) {
        console.error("Error saving order: ", error);
        alert("An error occurred while saving the order.");
      } finally {
        setUploading(false);
      }
    }
  };

  return (
    <div className="flex h-full items-center justify-center bg-black bg-opacity-50">
      <div className="flex max-h-[calc(100vh)] w-full max-w-lg flex-col overflow-auto bg-white shadow-xl">
        <div className="z-10 flex items-center justify-between border-b border-black bg-white">
          <div className="flex items-center">
            <button
              type="button"
              className="p-4 text-2xl text-gray-500"
              onClick={onBackToCart}
            >
              &larr;
            </button>
            <h1 className="text-2xl">Order</h1>
          </div>
          <button type="button" className="p-4 text-2xl text-gray-500" onClick={onClose}>
            ×
          </button>
        </div>

        <div className="flex-1 overflow-y-auto">
          <div className="border-b border-black">
            <label className="mt-2 block p-4 text-sm font-medium text-gray-700">
              How can we update you?
            </label>
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Phone number (8-digits)"
              className="text-md block w-full border-t border-black bg-[#f7f6eb] p-4"
            />
          </div>

          <div className="border-b border-black">
            <input
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              placeholder="Full Name"
              className="text-md block w-full border-b border-black bg-[#f7f6eb] p-4"
            />
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Address"
              className="text-md block w-full border-black bg-[#f7f6eb] p-4"
            />
          </div>

          <div className="pb-4">
            <label className="mt-8 block p-4 text-sm font-medium text-gray-700">
              Delivery to Fikse
            </label>
            <p className="mb-4 p-4 text-sm text-gray-500">
              If you have special requirements or want us to do measurements you need to
              deliver it to a drop-off.
            </p>
            <div className="relative">
              <select
                value={deliveryMethod}
                onChange={(e) => {
                  const value = e.target.value;
                  setDeliveryMethod(value);
                  // Only synchronize if the other method is a drop-off location
                  if (
                    dropoffLocations.includes(value) &&
                    dropoffLocations.includes(retrieveMethod)
                  ) {
                    setRetrieveMethod(value);
                  }
                }}
                className={`text-md block w-full appearance-none rounded-none border-t border-black p-4 ${
                  deliveryMethod === "Send it by courier + 89.-"
                    ? "bg-white"
                    : "bg-[#8cd7ff]"
                }`}
              >
                {dropoffLocations.map((location, index) => (
                  <option className="p-4" key={index} value={location}>
                    {location}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <ChevronDownIcon className="mr-4 h-6 w-6" aria-hidden="true" />
              </div>
            </div>
            <button
              type="button"
              onClick={() => setDeliveryMethod("Send it by courier + 89.-")}
              className={`text-md block w-full rounded-none border-b border-t border-black p-4 text-left ${
                deliveryMethod === "Send it by courier + 89.-"
                  ? "bg-[#8cd7ff]"
                  : "bg-white"
              }`}
            >
              Send it by courier + 89.-
            </button>
          </div>

          <div className="">
            <label className="mt-8 block p-4 text-sm font-medium text-gray-700">
              How do you want to retrieve your fixed item?
            </label>
            <div className="relative">
              <select
                value={retrieveMethod}
                onChange={(e) => {
                  const value = e.target.value;
                  setRetrieveMethod(value);
                  // Only synchronize if the other method is a drop-off location
                  if (
                    dropoffLocations.includes(value) &&
                    dropoffLocations.includes(deliveryMethod)
                  ) {
                    setDeliveryMethod(value);
                  }
                }}
                className={`text-md mt-4 block w-full appearance-none rounded-none border-b border-t border-black p-4 ${
                  retrieveMethod === "Home delivery + 89.-" ? "bg-white" : "bg-[#8cd7ff]"
                }`}
              >
                {dropoffLocations.map((location, index) => (
                  <option className="p-4" key={index} value={location}>
                    {location}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <ChevronDownIcon className="mr-4 h-6 w-6" aria-hidden="true" />
              </div>
            </div>
            <button
              type="button"
              onClick={() => setRetrieveMethod("Home delivery + 89.-")}
              className={`text-md mb-8 block w-full rounded-none border-b border-black p-4 text-left ${
                retrieveMethod === "Home delivery + 89.-" ? "bg-[#8cd7ff]" : "bg-white"
              }`}
            >
              Home delivery + 89.-
            </button>
          </div>

          <div className="border-b border-black">
            <h2 className="p-4 text-lg font-medium text-gray-900">
              Terms and conditions
            </h2>
            <div
              className="text-md flex cursor-pointer items-center justify-between border-t border-black p-4"
              onClick={() => setAcceptTerms(!acceptTerms)}
            >
              <label htmlFor="acceptTerms" className="text-md mr-4 w-2/3 text-gray-900">
                I accept{" "}
                <a href="#" className="text-[#5c6ef8] underline">
                  terms and conditions
                </a>{" "}
                including the storage of my data
              </label>
              <div className="relative h-12 w-12">
                <input
                  id="acceptTerms"
                  type="checkbox"
                  checked={acceptTerms}
                  readOnly
                  className="h-full w-full cursor-pointer appearance-none rounded-full border-2 border-black"
                />
                <div
                  className={`absolute inset-0 m-[1pt] flex items-center justify-center rounded-full transition-colors duration-300 ${
                    acceptTerms ? "bg-[#8cd7ff]" : ""
                  }`}
                >
                  {acceptTerms && <CheckIcon className="h-5 w-5" />}
                </div>
              </div>
            </div>

            <div
              className="text-md flex cursor-pointer items-center justify-between border-t border-black p-4"
              onClick={() => setReceiveMarketing(!receiveMarketing)}
            >
              <label
                htmlFor="receiveMarketing"
                className="text-md mr-4 w-2/3 text-gray-900"
              >
                I want to receive relevant marketing messages from Fikse
              </label>
              <div className="relative h-12 w-12">
                <input
                  id="receiveMarketing"
                  type="checkbox"
                  checked={receiveMarketing}
                  readOnly
                  className="h-full w-full cursor-pointer appearance-none rounded-full border-2 border-black"
                />
                <div
                  className={`absolute inset-0 m-[1pt] flex items-center justify-center rounded-full transition-colors duration-300 ${
                    receiveMarketing ? "bg-[#8cd7ff]" : ""
                  }`}
                >
                  {receiveMarketing && <CheckIcon className="h-5 w-5" />}
                </div>
              </div>
            </div>

            {receiveMarketing && (
              <div className="border-t border-black">
                <input
                  type="email"
                  value={marketingEmail}
                  onChange={(e) => setMarketingEmail(e.target.value)}
                  placeholder="What is your email?"
                  className="text-md block w-full border-b border-black bg-[#f7f6eb] p-4"
                />
              </div>
            )}
          </div>

          <div className="border-b border-black pb-4">
            <h2 className="mt-4 p-4 text-lg font-medium text-gray-900">Order</h2>
            {garments.map((item, index) => (
              <div key={index} className="mt-2 flex items-center justify-between p-4">
                <span>
                  {item.item} - {item.problem} ({item.fix_type})
                </span>
                <span>{item.price_nok} kr</span>
              </div>
            ))}
            <div className="mt-2 flex items-center justify-between">
              <span className="p-4">Total incl. VAT</span>
              <span className="p-4">{calculateTotalPrice(garments)} kr</span>
            </div>
          </div>

          <div className="mt-6 border-b border-black pb-4 pl-4">
            <p className="text-sm text-black">
              Ready within 10 days from when you deliver
            </p>
          </div>

          <div className="my-2 flex justify-center pb-4">
            <img
              src="https://www.fikse.co/images/illustration/footer-woman-cat.svg"
              alt="Illustration"
              className="h-auto w-24"
            />
          </div>
          <button
            type="button"
            onClick={handlePaymentClick}
            className={`mb-12 mt-auto w-full rounded-none border-b border-t border-black py-4 pl-4 text-left text-lg ${
              isFormValid
                ? "bg-[#D3FF8C] text-black"
                : "cursor-not-allowed bg-[#e0ffd0] text-gray-500"
            }`}
            disabled={!isFormValid || uploading}
          >
            {uploading ? "Uploading..." : "Proceed to payment"}
          </button>
        </div>

        <div className="my-2 text-center text-xs">
          <span>fikse.co ©</span>
        </div>
      </div>
    </div>
  );
};

export default CheckoutB2C;
