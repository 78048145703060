import { Fragment } from "react";

import { Dialog, Transition } from "@headlessui/react";

interface ModalTemplateProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
}

export default function ModalTile({
  open,
  onClose,
  title = "Modal Title",
  children,
}: ModalTemplateProps) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className="fixed inset-0 z-50" onClose={onClose}>
        {/* Backdrop */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {/* Modal Content Container */}
        <div className="fixed inset-0 z-10">
          <div className="fixed inset-x-0 bottom-0 flex justify-center sm:inset-0 sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-out duration-300"
              enterFrom="translate-y-full"
              enterTo="translate-y-0"
              leave="transform transition ease-in duration-200"
              leaveFrom="translate-y-0"
              leaveTo="translate-y-full"
            >
              <Dialog.Panel className="relative w-full transform bg-white text-left shadow-xl transition-all sm:my-8 sm:h-auto sm:max-w-lg">
                <div className="flex max-h-screen min-h-0 flex-col sm:max-h-[90vh]">
                  {/* Fixed Header Section */}
                  <div className="flex-shrink-0">
                    {/* Close Button */}
                    <div className="absolute right-0 top-0 pr-4 pt-4">
                      <button
                        type="button"
                        className="bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2"
                        onClick={onClose}
                      >
                        <span className="sr-only">Close</span>
                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>

                    {/* Title */}
                    <h1 className="ml-6 pb-4 pt-4 text-3xl tracking-tight">{title}</h1>
                  </div>

                  {/* Scrollable Content Section */}
                  <div className="flex-1 overflow-y-auto">
                    <div>{children}</div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
