import Categories from "../components/home/Categories";
import Instructions from "../components/home/Instructions";
import MobileView from "../components/home/MobileView";
import OsloKommuneLandingPage from "../components/home/OsloKommuneLandingPage";

// Import the MobileView component

export default function Home() {
  console.log(localStorage.getItem("uid"));
  console.log(localStorage.getItem("department"));
  const domain = window.location.hostname;
  console.log(domain);

  return (
    <>
      <div className="block">
        {domain === "oslo.kommune.fikse.co" || domain === "dev.oslo.kommune.fikse.co" ? (
          <OsloKommuneLandingPage />
        ) : (
          <MobileView />
        )}
      </div>
      <div className="hidden">
        {/* Desktop View */}
        <div className="grid grid-cols-1 sm:grid-cols-2">
          <Categories />
          <Instructions />
        </div>
      </div>
    </>
  );
}
