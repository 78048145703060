import React, { Fragment, useEffect, useState } from "react";

import { Menu, Transition } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import { collection, getDocs, query } from "firebase/firestore";
import { useTranslation } from "react-i18next";

import { db } from "../firebase";

const defaultImageUrl: string =
  "https://us.123rf.com/450wm/koblizeek/koblizeek2208/koblizeek220800254/190563481-no-image-vector-symbol-missing-available-icon-no-gallery-for-this-moment-placeholder.jpg";
const statuses = {
  Paid: "text-green-700 bg-green-50 ring-green-600/20",
  Withdraw: "text-gray-600 bg-gray-50 ring-gray-500/10",
  Overdue: "text-red-700 bg-red-50 ring-red-600/10",
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface Order {
  id: string;
  status: string;
  contactInfo: {
    address: string;
    department: string;
    name: string;
    phone: string;
    email: string;
  };
  deliveryAddress: string;
  deliveryDepartment: string;
  totalPrice: number;
  date: string;
  email: string;
  customerRef: string;
  orderInfo: Array<{
    customerComment: string;
    description: string;
    fix_type: string;
    id: string;
    imageUrl: string;
    item: string;
    placeholder: string;
    price_nok: number;
    problem: string;
    work: string;
  }>;
  test?: boolean; // Optional field for testing flag
}

export default function AhlsellPage() {
  const [orders, setOrders] = useState<Order[]>([]);
  const [showDetails, setShowDetails] = useState<{ [key: string]: boolean }>({});
  const userEmail = localStorage.getItem("uid");
  const { t } = useTranslation();

  useEffect(() => {
    const fetchOrders = async () => {
      if (!userEmail) return;

      const q = query(collection(db, "workwearOrders"));
      const querySnapshot = await getDocs(q);
      let userOrders: Order[] = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Order[];

      // Filter out orders where test == true
      userOrders = userOrders.filter(
        (order) =>
          !order.test &&
          (order.email.endsWith("oslo.kommune.no") ||
            order.email.endsWith("osloskolen.no") ||
            order.email.endsWith("fikse.co"))
      );

      // Sort orders by date in descending order
      userOrders.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

      // Sort orders by date in descending order
      setOrders(userOrders);
    };

    fetchOrders();
  }, [userEmail]);

  const toggleDetails = (orderId: string) => {
    setShowDetails((prevDetails) => ({
      ...prevDetails,
      [orderId]: !prevDetails[orderId],
    }));
  };

  const confirmOrder = (orderId: string) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.id === orderId ? { ...order, status: "confirmed" } : order
      )
    );
  };

  return (
    <div className="mx-8 my-8">
      <header className="mb-8">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900">
          {t("ahlsellPage.loggedInAs")}
        </h1>
        <p className="text-lg text-gray-600">{t("ahlsellPage.overview")}</p>
      </header>
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {orders.map((order) => (
          <li
            key={order.id}
            className="flex flex-col justify-between overflow-hidden border border-gray-200 hover:shadow"
          >
            <div>
              <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                <div className="truncate text-lg font-medium leading-6 text-gray-900">
                  {t("ahlsellPage.orderId")}: {order.id.slice(0, 5)}
                </div>
                <Menu as="div" className="relative ml-auto">
                  <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                    <span className="sr-only">{t("ahlsellPage.openOptions")}</span>
                    <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                  <Transition
                    as={React.Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href={`/orders/workwear/${order.id}`}
                            className={`${
                              active ? "bg-gray-50" : ""
                            } block px-3 py-1 text-sm leading-6 text-gray-900`}
                          >
                            WorkWearPage
                            <span className="sr-only">, {order.customerRef}</span>
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                <div className="flex justify-between gap-x-4 py-3">
                  <dt className="text-gray-500">{t("ahlsellPage.date")}</dt>
                  <dd className="text-gray-700">
                    <time dateTime={new Date(order.date).toISOString()}>
                      {new Date(order.date).toLocaleDateString()}
                    </time>
                  </dd>
                </div>
                <div className="flex justify-between gap-x-4 py-3">
                  <dt className="text-gray-500">{t("ahlsellPage.estimatedPrice")}</dt>
                  <dd className="flex items-start gap-x-2">
                    <div className="font-medium text-gray-900">{order.totalPrice}</div>
                  </dd>
                </div>

                <div className="flex justify-between gap-x-4 py-3">
                  <dd className="truncate text-gray-700">{order.contactInfo.name}</dd>
                </div>
                <div className="flex justify-between gap-x-4 py-3">
                  <dd className="truncate text-gray-700">{order.email}</dd>
                </div>
                <div className="flex justify-between gap-x-4 py-3">
                  <dt className="text-gray-500">{t("ahlsellPage.phoneNumber")}</dt>
                  <dd className="text-gray-700">{order.contactInfo.phone}</dd>
                </div>
                <div className="flex flex-wrap justify-between gap-x-4 py-3">
                  <dt className="w-full text-gray-500 sm:w-auto">
                    {t("ahlsellPage.returnAddress")}
                  </dt>
                  <dd className="w-full break-words text-gray-700 sm:w-auto sm:text-right">
                    {order.deliveryAddress}
                  </dd>
                </div>

                <div className="flex flex-wrap justify-between gap-x-4 py-3">
                  <dt className="w-full text-gray-500 sm:w-auto">
                    {t("workwearOrderPage.department")}
                  </dt>
                  <dd className="w-full break-words text-gray-700 sm:w-auto sm:text-right">
                    {order.deliveryDepartment}
                  </dd>
                </div>
              </dl>
            </div>
            <div>
              <button
                className="mt-4 w-full bg-gray-200 py-2 text-gray-900 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                onClick={() => toggleDetails(order.id)}
              >
                {showDetails[order.id]
                  ? t("ahlsellPage.hideItems")
                  : t("ahlsellPage.showItems")}
              </button>
              {showDetails[order.id] && (
                <div className="m-4 space-y-4">
                  {order.orderInfo.map((item, index) => (
                    <div key={index} className="py-3">
                      <dt className="whitespace-normal break-words font-medium text-gray-500">
                        {index + 1}: {item.item}
                      </dt>
                      {/*prettier-ignore*/}
                      <dd className="whitespace-normal break-words text-gray-700">
                        {t("ahlsellPage.description")}:   {item.description}, {item.fix_type}
                      </dd>
                      {/*prettier-ignore*/}
                      <dd className="whitespace-normal break-words text-gray-700">
                        {t("ahlsellPage.problem")}: {item.problem}, {item.work}
                      </dd>
                      <dd className="whitespace-normal break-words text-gray-700">
                        {t("ahlsellPage.customerComment")}: {item.customerComment}
                      </dd>
                      <dd className="whitespace-normal break-words text-gray-700">
                        {t("ahlsellPage.price_nok")}: {item.price_nok} NOK
                      </dd>

                      {item.imageUrl && item.imageUrl !== defaultImageUrl ? (
                        <img
                          src={item.imageUrl || "https://via.placeholder.com/48"}
                          className="mt-2 h-28 w-28 flex-none bg-white object-cover ring-1 ring-gray-900/10"
                          alt={item.work}
                        />
                      ) : null}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
