// auth.ts
import { OAuthProvider, getAuth, signInWithPopup, signOut } from "firebase/auth";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";

import { app } from "./firebase";

const auth = getAuth(app);
const db = getFirestore(app);

const synchronizedUser = async (user: any) => {
  localStorage.setItem("uid", user.email);
  localStorage.setItem("verifiedUid", user.email);

  // Reference to the user's document in Firestore
  const userDocRef = doc(db, "users", user.email);
  const userDocSnap = await getDoc(userDocRef);
  const userData = userDocSnap.data();
  if (userData) {
    localStorage.setItem("roles", userData.roles);
  }

  if (!userDocSnap.exists()) {
    // Create a new document for the user
    await setDoc(userDocRef, {
      email: user.email,
      uid: user.uid,
      roles: [],
      // Add other user-specific fields as needed
    });
  }
};

export const signInWithMicrosoft = async () => {
  const provider = new OAuthProvider("microsoft.com");

  try {
    const result = await signInWithPopup(auth, provider);
    // User is signed in.
    const user = result.user;
    // Access tokens
    const credential = OAuthProvider.credentialFromResult(result);
    const accessToken = credential?.accessToken;
    const idToken = credential?.idToken;
    console.log("User signed in:", user.email);

    if (user.email) {
      console.log("Insid user email");
      await synchronizedUser(user);
    }

    // Handle user information and tokens as needed
  } catch (error) {
    // Handle errors here
    console.error("Authentication error:", error);
  }
};

export const signOutUser = async () => {
  try {
    await signOut(auth);
    localStorage.removeItem("roles");
    localStorage.removeItem("uid");
    localStorage.removeItem("verifiedUid");

    // Sign-out successful.
  } catch (error) {
    // An error happened.
    console.error("Sign-out error:", error);
  }
};
